import React,{useEffect, useState} from "react";

const CreateChatPage = (props)=>{
    const [form,setForm]=useState({});
    const [user,setUser]=useState({});
    const [ready,setReady]=useState(false)
   
    const sendClickFirstTime=(e)=>{
        e.preventDefault();
        localStorage.removeItem('newChat')
        let data = {
                "action": "initConversation",
                "data":{
                    "toUserId": user.id,
                    "text":form.newMsg
                } 
        }
        setForm({newMsg:''})
        // if (!ws.current || ws.current.readyState==3){
        //     connect(JSON.stringify(data))
        // }
        // else{
            props.ws.current.send(JSON.stringify(data));
        // }
       
    }

    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
    }

    useEffect(()=>{
        if(!ready){ 
            setUser(JSON.parse(localStorage.getItem('newChat')))
            setReady(true);
            debugger
        }   
    },[user,ready])

        return (
        <>
            <div className='chatHeader' > 
                <div className='chatHeaderSub'>
                        <div className='avatar title2' style={{backgroundColor:`${user && user.nicknameColor?user.nicknameColor:'green'}`}}> 
                        {user && user.nickname && user.nickname[0].toUpperCase()}
                    </div>
                    <div className='statusType'>
                            <div className='online'></div>
                    </div>
                    <div className='title3'>{user && user.nickname}</div>
                </div>
            </div>

            <div className='chatList' style={{height:'inherit'}}>
                <div className='chatMsg' style={{'alignSelf':'center'}}>
                    No messages
                </div>
            </div>
            <form className='chatInputContainer' onSubmit={sendClickFirstTime}>
                <div className='icon btn'>
                    <div className='attachIcon'> 
                    </div>
                </div>
                <input type="text" placeholder='Сообщение...' className='chatInput' id='newMsg' name='newMsg' value={form.newMsg?form.newMsg:''} onChange={changeHandler}/>
                <div className='icon btn' >
                    <div className='sendIcon'  onClick={sendClickFirstTime}> 
                    </div>
                </div>
                {/* <button type='submit' onClick={sendClick}>send</button> */}
            </form>
        </>
    )
}

export default CreateChatPage;