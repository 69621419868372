import React,{useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { uploadFile } from "../../../redux/actions/upload";

const chunkSize = 1048576 * 1;//its  MB, increase the number measure in mb


const Upload =(props)=>{
    const dispatch=useDispatch();
    const [multipleFiles,setMultipleFiles] = useState([]);
    const [counter, setCounter] = useState(0);
    const currentChatId = useParams().id;
    const [fileToBeUpload, setFileToBeUpload] = useState({});
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
    const [progress, setProgress] = useState(0);
    const [fileGuid, setFileGuid] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [chunkCount, setChunkCount] = useState(0);
    const [open,setOpen] = useState('')
    const [fileList, setFileList]=useState([]);
    const [chunkSet, setChunkSet]=useState();
    const {messages} = useSelector(state=>state);

    

    useEffect(() => {
        if (fileSize > 0 && counter>=0) {
          fileUpload(counter);
        }
      }, [fileToBeUpload,progress,multipleFiles])
      
      const getFileContext = (e) => {
        // let types=[...fullList.FileTypes[1].title.split(' ')]
        resetChunkProperties();
        let fileListArr = [];
      
        if(e.target && e.target.files){
          fileListArr= [...e.target.files];
        }else if(e.dataTransfer && e.dataTransfer.files){
        e.preventDefault();
          fileListArr= [...e.dataTransfer.files];
        }
        else{
          fileListArr=multipleFiles;
          setCounter(0);
        }
        
        if(fileListArr.length>0){
          const _file = fileListArr[0];
          fileListArr.splice(0, 1);
          setMultipleFiles(fileListArr);
        //   if(_file.size>10000000){
        //   setAlertMessage('Не більше 10МБ')
        //   setAlertModal(true);
        //     return
        //   }
        //   if(!types.find(a =>a.includes(_file.name.split('.')[_file.name.split('.').length - 1].toLowerCase()))){
        //     setAlertMessage('Не вірний формат файлу')
        //     setAlertModal(true);
        //     return 
        //   }
      
          setFileSize(_file.size)
          const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
          setChunkCount(_totalCount)
          setFileToBeUpload(_file)
          const _fileID = _file.name;
          setFileGuid(_fileID+Math.floor(1000 + Math.random() * 9000));
          // fileUpload();
        }else {
          setMultipleFiles([]);
        }
      }

      const removeByAttr = function(arr, attr, value){
        let i = arr.length;
        while(i--){
           if( arr[i] 
               && arr[i].hasOwnProperty(attr) 
               && (arguments.length > 2 && arr[i][attr] === value ) ){ 
               arr.splice(i,1);
           }
        }
        return arr;
      }
      
      const fileUpload = () => {
        setCounter(counter + 1);
        if (counter>=0 && counter <= chunkCount) {
          let chunk = fileToBeUpload;
          uploadChunk(chunk)
        }else if(counter>0 && counter>chunkCount){
          setCounter(0);
        }
      }
      
      const uploadChunk = async (chunk) => {
        try {
          let formData = new FormData();

    
        //   "fileTypeId": "1", //all file types here 
        //   "filePurposeTypeId": "2", //all file purpose types here      

          formData.append('fileName', chunk.name);
          formData.append('size', fileSize);
          formData.append('chunkIndex', counter);
          formData.append('totalChunk', chunkCount);
          formData.append('fileTypeId', "1");
          formData.append('filePurposeTypeId', "2");
          formData.append('file',chunk.slice(beginingOfTheChunk,endOfTheChunk,fileToBeUpload.type));
          formData.append('chunkId',chunk.name+chunkSize);
          formData.append('uploadType','sportsground_photo');
          setChunkSet(chunk);   
        let response = await dispatch(uploadFile(formData))
          const data = response.data;
                debugger
          if (response.status === 200) {
            setBeginingOfTheChunk(endOfTheChunk);
            setEndOfTheChunk(endOfTheChunk + chunkSize);
            if (counter == chunkCount) {
              console.log('Process is complete, counter', counter)
              // await uploadCompleted();
            } else {
              let percentage = (counter / chunkCount) * 100;
              setProgress(percentage);
            }
          } else if(response.status ===201){
            setFileToBeUpload({})
            setProgress(100);
            let res = [...fileList];
            
            let obj = {
              name: fileGuid.split('.')[0],
              size: fileSize,
              type:  fileGuid.split('.')[1],
              link: data.response.url
            }
            res.push(obj);
            let arr = []
            res.forEach(el=>{
             arr.push(el.link)
            })


            // props.form.photos=arr;
            // props.setFrom(props.form);
            // dispatch(editPark(props.form)).then(res=>{
            //   props.setFrom({...props.form})
            // })

            sendClick(obj.link)
            setFileList(res);
            getFileContext(multipleFiles);
          }
          else{
            console.log('Error Occurred:', data.errorMessage)
          }
      
        } catch (error) {
          
          console.log('error', error)
        }
      }

      const resetChunkProperties = () => {
        // setShowProgress(true);
        setProgress(0);
        setFileGuid('');
        setCounter(0);
        setBeginingOfTheChunk(0);
        setEndOfTheChunk(chunkSize);
        setFileSize(0);
      }

      const sendClick=(url)=>{
        let data = {
            "action": "newChatMessage",
            "data":{
                "chatId": currentChatId,
                "text": 'File',
                "fileUrl": url,
                "messageTypeId": "AAAAAAA0",
            }
        }
        props.ws.current.send(JSON.stringify(data));

        if(messages.length===1&&messages[0].messageType==='emptyChat'){
            let del={
                action: "deleteChatMessage",
                data:{
                    messageId: messages[0].id,
                    chatId: messages[0].chatId
                }
            }
            props.ws.current.send(JSON.stringify(del))
        }
        props.scrollToBottom()
    }


    return(
        <>
            <label className='icon btn' htmlFor="fileAttach">
                <div className='attachIcon'> 
                </div>
            </label>
            <input type="file" name="fileAttach" id="fileAttach" multiple onChange={getFileContext} style={{'display':'none'}}/>
        </>
    )

}

export default Upload;