import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEng from "./locales/eng/translation.json";
import translationRu from "./locales/ru/translation.json";
import translationUa from "./locales/ua/translation.json";
import translationDe from "./locales/de/translation.json";

let val = localStorage.getItem('i18nextLng') 

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: val,
    fallbackLng: "en", // use ru if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    resources: {
      en: {
        translations: translationEng
      },
      ru: {
        translations: translationRu
      },
      ua: {
        translations: translationUa
      },
      de: {
        translations: translationDe
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;