import axios from "axios";

import constants from '../../constants/constant.json';
import authHeader from "./auth-header";

const addPushToken = (data)=>{
     console.log(data.pushToken)
    return axios.post(constants.URL + '/push/set-token',{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}
export default{
    addPushToken
}