import {
    LISTID_SUCCESS
}from "../actions/types";

const initialState = [];

export default function dictionariesReducer(listId = initialState, action) {
    const { type,list } = action;
    switch (type) {
          case LISTID_SUCCESS:
          return {list:list};
        default:
          return listId;
    }
}