import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";

const MessageItem = (props)=>{
    const itemRef = useRef(null);
    const {currentUser}=useSelector(state=>state.currentUser);
    // console.log(itemRef.current?.offsetTop);

    const setRead=()=>{
        if(((props.messagesEndRef.current.scrollTop + window.innerHeight)-60 >itemRef.current?.offsetTop && !props.el.isRead && !props.el.isMine )||(!props.el.isRead && props.el.messageType==="emptyChat")){
                    // console.log(props.height)
                    let data={
                         action:"readMessage",
                         data:{
                             "chatId":props.el.chatId,
                             "messageId":props.el.id
                         }
                    }
                    props.ws.current.send(JSON.stringify(data))
                } 
    }

    useEffect(() => { 
         if(((props.messagesEndRef.current.scrollTop + window.innerHeight)-60 >itemRef.current?.offsetTop && !props.el.isRead && !props.el.isMine )||(!props.el.isRead && props.el.messageType==="emptyChat") ){     
           setRead();
         }
    }, [props.height]);


    const deleteClick=()=>{
        let data={
            action: "deleteChatMessage",
            data:{
                messageId: props.el.id,
                chatId: props.el.chatId
            }
        }
        props.ws.current.send(JSON.stringify(data))
    }

    const editClick=()=>{
        props.setForm({editId:props.el.id,editMsg:props.el.text})
    }

    const forwardClick=()=>{
        
    }
    const replyClick=()=>{

    }
    if( props.el.messageType==="system"){
        return(
        <div ref={itemRef} key={props.el.id} className='chatMsg' style={{'alignSelf':'center'}}>
                {props.el.text}
        </div>
        )
    }
    else{
    return(
        <>{props.el.messageType==="emptyChat"?
            <div key={props.el.id} ref={itemRef}  id={props.el.id}  className='chatMsg' style={{'alignSelf':'center'}}>
                {props.el.text}
            </div>
        :
            <div key={props.el.id} ref={itemRef} id={props.el.id} className='chatMsg'>
                <div className='avatar title2' style={{backgroundColor:`${props.el.fromUser.nicknameColor?props.el.fromUser.nicknameColor:'black'}`}}> 
                    {props.el.fromUser && props.el.fromUser.login && props.el.fromUser.login[0].toUpperCase()}
                </div>
               <div  className='msgBody'>
                   <div className="msgInfo">
                        <span className="caption1">{props.el.fromUser.login}</span>
                        <span style={{marginLeft:'8px'}} className='caption2 '>{new Date(props.el.createdAt).toLocaleString().slice(0,-3).split(', ')[1]}</span>
                        {props.el.isRead&&<span className="readedStatus"></span>}
                        {props.el.isEdited&&<span className="caption2">изменено</span>}
                        </div>
                        <div className={props.el.text?"msgText text3 textWithImg":'msgText text3'}>{props.el.text?props.el.text:''}</div>
                    {
                      props.el.file &&  props.el.file.fileType==='image'&&
                        <img src={props.el.file.url} style={{maxHeight:'300px',maxWidth:'300px'}}  className={props.el.text?"msgText text3 imgWithText":'msgText text3'}></img> 
                    }
                    {
                      props.el.file &&  props.el.file.fileType==='video'&&
                        <video controls style={{maxHeight:'300px',maxWidth:'300px'}}   className={props.el.text?"msgText text3 imgWithText":'msgText text3'}>
                        <source src={props.el.file.url} type={props.el.file.mimeType}></source>
                        </video> 
                    }
                    {
                      props.el.file &&  props.el.file.fileType==='audio'&&
                        <audio controls style={{maxHeight:'300px',maxWidth:'300px'}}   className={props.el.text?"msgText text3 imgWithText":'msgText text3'}>
                            <source src={props.el.file.url} ></source>
                        </audio> 
                    }
                    {
                      props.el.file &&  props.el.file.fileType==='other'&&
                      <>
                      </>
                        // <audio controls style={{maxHeight:'300px',maxWidth:'300px'}}  className="msgText text3">
                        //     <source src={props.el.file.url} type={props.el.file.mimeType}></source>
                        // </audio> 
                    }
                  
               </div>
               <div className='icon msgButoon' >
                    <div className='moreIcon' >
                    </div>
                    <div className="moreModal">
                        <div className="moreModalItem">Delete</div>
                        <div className="moreModalItem">Forward</div>
                        <div className="moreModalItem">Reply</div>      
                    </div>
                </div>

               
               {/* {props.el.text}
                <div>{new Date(props.el.createdAt).toLocaleString().slice(0,-3)} <div>{props.el.isChanged?'edited':''}</div> </div>
                <br/>
                <div style={{textAlign:'right'}}>
                    <div>{props.el.isRead?'readed':'notReaded'}</div>                   
                    {props.el.isMine && <><br/>
                    <button onClick={editClick}>Edit</button>
                    <br/>
                    <button onClick={deleteClick}>Delete</button>
                    </>}
                </div> */}
            </div>}
        </>
    )
    }
}

export default MessageItem;