import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { registerServiceWorker } from './serviceWorker';

ReactDOM.render(
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>

        <Provider store={store}>
          <App/>
        </Provider>
      </I18nextProvider>
    </BrowserRouter>,
  document.getElementById('root')
);
   
registerServiceWorker();

