import React, {Suspense, useEffect, useCallback, useState, useRef } from 'react'
import UseRouts from './routes';
import './App2.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, retrieveUsers } from "./redux/actions/users";
import { logout } from "./redux/actions/auth";
import { useHistory } from 'react-router';
import Loader from './components/Loader/Loader';
import { getDictionariesList, getFullList } from './redux/actions/dictionaries';
import SideBar from './components/SideBar/SideBar';
import { fetchToken, onMessageListener } from './firebaseInit';
import { addPushToken } from './redux/actions/firebase';
import { addChatroomItemWs, deleteChatroomItem, getChatroomListWs, setCurrentChat, setCurrentChatWithMessage } from './redux/actions/chatroom';
import { useParams } from 'react-router-dom';
import { addMessagesItem, addMessagesItemWs, addMessagesItemWsAnotherChat, deleteMessagesItem,deleteMessagesItemAnotherChat, editMessagesItem, getMessagesList, setReaded } from './redux/actions/messages';
import Timer from './components/Timer/Timer';

function App() {
const { auth} = useSelector((state) => state);
const {currentUser} = useSelector((state) => state.currentUser);
const {chatroom} = useSelector((state) => state);
const { fullList} = useSelector((state) => state);
const [ready,setReady]=useState(false)
const dispatch = useDispatch();
const history = useHistory();
const ws = useRef(null);
const currentChatId = useParams().id;

const [timer, setTimer] = useState(55);

const [show, setShow] = useState(false);
const [notification, setNotification] = useState({title: '', body: ''});
const [received, setReceived] = useState(true);


onMessageListener().then(payload => {
  console.log(payload);
  setNotification({title: payload.data.fromUserLogin, body: payload.data.messageText})
  setShow(true);
  // add listener

}).catch(err => console.log('failed: ', err)); 



window.onbeforeunload = function (e) { 
  // Ловим событие для Interner Explorer 
  console.log('logOuytClick')
  
  // Для Safari и Chrome 
  // return myMessage; 
}; 

const logOut = useCallback(() => {
  // history.push("/applications");
  dispatch(logout())
}, [dispatch]);

  const getDict=()=>{
    dispatch(getFullList()).then(res=>{
    })
  }

const fireNot=()=>{
    fetchToken()
    .then((firebaseToken) => {
      console.log('firebaseToken');
      console.log(firebaseToken);
      let data={
        'pushToken': firebaseToken,
      }
      dispatch(addPushToken(data))
      // eslint-disable-next-line no-console
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
  }
 

  const connect=(text)=> {
    let data={
      "action": "auth",
      "data":{
          "token":`Bearer ${auth.user.token}`
      }
  }
    ws.current = new WebSocket("wss://chat2.weltwelle.com/ws"); // создаем ws соединение
    ws.current.onopen=()=>{
      ws.current.send(JSON.stringify(data))
      gettingData();
  };
    if(text){
        ws.current.send(text)
    }


}



const gettingData = useCallback(() => {
  ws.current.onmessage= e => { 
    const message = JSON.parse(e.data);
    
    if(message.action==='connection'){
      console.log('connection')
      setReady(true)

    }

    if(message.action==='auth'){
      console.log('auth')
      setReady(true)
    }

////////////////////////////////////////////////////
    if(message.action==='getChatList'){
      dispatch(getChatroomListWs(message.result))
    }
    else if (message.action==='newChatMessage'){
      if(message.result.chatId===currentChatId || message.result.chatId===document.location.pathname.split('/')[2]){
        dispatch(addMessagesItemWs(message.result))
      }else{
        dispatch(addMessagesItemWsAnotherChat(message.result))
      }
    }
    else if(message.action==="initConversation"){
        dispatch(addChatroomItemWs(message.result))
        dispatch(addMessagesItem(message.result.lastMessage))
        history.push(`/chat/${message.result.id}`)
        
    }
    else if(message.action==="newChat"){
       
      dispatch(addChatroomItemWs(message.result))
      history.push(`/chat/${message.result.id}`)
  }
    else if(message.action==="getChatById"){
        dispatch(setCurrentChat(message));
    }
    else if(message.action==="getChatByIdWithMessages"){
        dispatch(setCurrentChatWithMessage(message));
    }
    else if(message.action==="getMessageList"){
      dispatch(getMessagesList(message.result));
    }
    else if(message.action==="switchNotification"){
        dispatch(setCurrentChat(message));
    }
    else if(message.action==="changeChatMessage"){
      dispatch(editMessagesItem(message));
    }
    else if(message.action==="deleteChatMessage"){
      if(message.result.replacedMessage.chatId===currentChatId || message.result.replacedMessage.chatId===document.location.pathname.split('/')[2]){
        dispatch(deleteMessagesItem(message));
      }else{
        dispatch(deleteMessagesItemAnotherChat(message));
      }
    }
    else if(message.action==="readMessage"){
      dispatch(setReaded(message));
    }
    else if(message.action==="deleteChat"){
      dispatch(deleteChatroomItem(message))
    }
    else if(message.action==="getUserList"){
      dispatch(retrieveUsers(message))
    }

  }        

},[chatroom,currentChatId]);





useEffect(() => {
  if(!ready){
    if (auth.user) {
      setReady(false);
      dispatch(getCurrentUser(auth.user.payload.id)).then(res=>{
        fireNot();
        connect();
      })
    } else {
      logOut();
      setReady(true);
    }
    getDict();}
    if(ws.current && ws.current.readyState!==1 && ready){
       
      connect();
    }
}, [auth.user]);



  return (
    <div className="App">
    {auth.isLoggedIn && <Timer timer={timer} setTimer={setTimer} ws={ws}/>}
    <Suspense fallback={<Loader />}>
      {auth.isLoggedIn &&  <SideBar ws={ws} readyWs={ready} user={auth.user} connect={connect} />}
      {/* <Loader /> */}
      <div className="mainLine">
        {ready&& <UseRouts auth={auth} ws={ws} readyWs={ready} currentUser={currentUser} received={received}/>}
      </div>
      {/* <button onClick={fireNot}>Click click </button> */}
    </Suspense>
    
  </div>
  );
}

export default App;
