import{
    MESSAGESLIST_SUCCESS,
    MESSAGESLIST_FAIL,
    MESSAGESDELETE_SUCCESS,
    MESSAGESDELETE_FAIL,
    MESSAGESEDIT_SUCCESS,
    MESSAGESEDIT_FAIL,
    MESSAGESADD_SUCCESS,
    MESSAGESADD_FAIL,
    MESSAGESITEM_SUCCESS,
    MESSAGESITEM_FAIL,
    MESSAGESADD_ANOTHERCHAT_SUCCESS,
    CHATROOMREADED_SUCCESS,
    CHATROOMITEMREADED_SUCCESS
} from './types'

import MessagesService from '../services/messages.service';


    export const getMessagesList = (data)=>(dispatch)=>{
        dispatch({
           type: MESSAGESLIST_SUCCESS,
           list:data.items,
           scrollType:data.type
        });


        // return MessagesService.getMessagesList(data).then(
        //     (response)=>{
        //         let data= response;
        //         data.items= data.items.reverse();
        //         try{
        //         dispatch({
        //             type: MESSAGESLIST_SUCCESS,
        //             list:response,
        //         });
        //         return response
        //         }
        //         catch{
        //         return Promise.reject();
        //         }
        //     },
        //     (error)=>{
        //         dispatch({
        //             type:  MESSAGESLIST_FAIL,
        //         });
        //         console.log(error);
                    
        //         return Promise.reject();
        //     }
        // );

    }

    export const deleteMessagesItem= (data)=>(dispatch)=>{
                dispatch({
                    type: MESSAGESDELETE_SUCCESS,
                    item:data
                });
                dispatch({
                    type: MESSAGESADD_ANOTHERCHAT_SUCCESS,
                    item:data.result.replacedMessage
                });

                // if(data.result.replacedMessage.messageType==='emptyChat'){
                //     dispatch({
                //         type: MESSAGESADD_SUCCESS,
                //         item:data.result.replacedMessage
                //     });
                // }
    }
    export const deleteMessagesItemAnotherChat= (data)=>(dispatch)=>{
        dispatch({
            type: MESSAGESADD_ANOTHERCHAT_SUCCESS,
            item:data.result.replacedMessage
        });
    }

    export const editMessagesItem= (data)=>(dispatch)=>{
        debugger
                dispatch({
                    type: MESSAGESEDIT_SUCCESS,
                    item:data
                });
                if(data.isLast){   ////////
                    dispatch({
                        type: MESSAGESADD_ANOTHERCHAT_SUCCESS,
                        item:data
                    });
                }

    }
    export const setReaded= (data)=>(dispatch)=>{
        debugger
        dispatch({
            type: MESSAGESEDIT_SUCCESS,
            id:data
        });

        dispatch({
            type: CHATROOMREADED_SUCCESS,
            id:data,
            status:'decrease'
        });

        dispatch({
            type: CHATROOMITEMREADED_SUCCESS,
            id:data,
            status:'decrease'
        });
        
        if(data.isLast){   ////////
            dispatch({
                type: MESSAGESADD_ANOTHERCHAT_SUCCESS,
                id:data,
                status:'decrease'
            });
        }
    }


    

    export const addMessagesItemWs=(data)=>(dispatch)=>{
                dispatch({
                    type: MESSAGESADD_SUCCESS,
                    item:data
                });

                dispatch({
                    type: MESSAGESADD_ANOTHERCHAT_SUCCESS,
                    item:data,
                    status:'increase'
                });

                dispatch({
                    type: CHATROOMITEMREADED_SUCCESS,
                    id:data,
                    status:'increase'
                });

                return data

    }
    export const addMessagesItem=(data)=>(dispatch)=>{
        dispatch({
            type: MESSAGESADD_SUCCESS,
            item:data
        });
        return data
    }


    export const setMessagesClear=(data)=>(dispatch)=>{
         
        dispatch({
            type: MESSAGESLIST_FAIL,
        });
        return data
    }

    export const addMessagesItemWsAnotherChat=(data)=>(dispatch)=>{
        dispatch({
            type: MESSAGESADD_ANOTHERCHAT_SUCCESS,
            item:data,
            status:'increase'
        });
        
        return data
    }

    



    // export const addMessagesItem= (form)=>(dispatch)=>{
      
    //     return MessagesService.addMessagesItem(form).then(
    //         (response)=>{
                  
                  
    //             dispatch({
    //                 type: MESSAGESADD_SUCCESS,
    //                 list:response
    //             });
                  
    //             return response

    //         },
    //         (error)=>{
    //             dispatch({
    //                 type: MESSAGESADD_FAIL,
    //               });
    //             console.log(error);
                     
    //               return Promise.reject();
    //         }
    //     );
    // }


    export const getMessagesItem = (data)=>(dispatch)=>{
        return MessagesService.getMessagesItem(data).then(
            (response)=>{
                  
                dispatch({
                    type: MESSAGESITEM_SUCCESS,
                    list:response,
                });
                  
                return response
    
            },
            (error)=>{
                dispatch({
                    type:  MESSAGESITEM_FAIL,
                  });
                console.log(error);
                     
                  return Promise.reject();
            }
        );
    
    }


