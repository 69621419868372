import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDictionariesItem, deleteDictionariesItem, editDictionariesItem, getDictionariesList,getAdditionalDictionariesList } from '../../redux/actions/dictionaries';
import Loader from './../../components/Loader/Loader'


const DirectoryPage =()=>{
  const [selectedType,setSelectedType] = useState('');
  const [selectedAdditionalType,setSelectedAdditionalType] = useState('');
  const [newElement,setNewElement] = useState('');
  const [newAdditionalElement,setNewAdditionalElement] = useState('');
  const dispatch = useDispatch();
  const [ready,setReady] = useState(false);
  const [readyAdd,setReadyAdd] = useState(false);
  const {dictionaries} = useSelector((state) => state);
  const {dictionariesAdditional} = useSelector((state) => state);
  const [form,setForm]=useState({});
  const [editElement,setEditElement] = useState('');
  const [deleteModal,setDeleteModal] = useState(false);
  const [deleteAdditionalModal,setDeleteAdditionalModal] = useState(false);
  const [deleteElement,setDeleteElement] = useState('');
  const clickHandler=(e)=>{ 
    if(e.target.id===selectedType){
      setSelectedType('')
    }else if(e.target.id!==selectedType){
      setReady(false);
      setSelectedType(e.target.id)
      dispatch(getDictionariesList(e.target.id)).then(res=>{
          
        setReady(true);
      }).catch((e)=>{
        console.log(e);
        setReady(true);
      });
    }
  }
  const additionalClick =(e)=>{
    if(e.target.id===selectedAdditionalType){
      setSelectedAdditionalType('')
    }else if(e.target.id!==selectedAdditionalType){
      setSelectedAdditionalType(e.target.id)
      setReadyAdd(false);
      dispatch(getAdditionalDictionariesList(e.target.id)).then(res=>{
          
        setReadyAdd(true);
      }).catch((e)=>{
        console.log(e);
        setReadyAdd(true);
      });
    }
  }

  const additionalSubClick=(e)=>{
    if(e.target.id===selectedAdditionalType){
      setSelectedAdditionalType('')
    }else if(e.target.id!==selectedAdditionalType){
      setSelectedAdditionalType(e.target.id)
    }
  }

  const getAdditionalList=()=>{
    setReadyAdd(false);
    dispatch(getAdditionalDictionariesList(selectedAdditionalType)).then(res=>{
        
      setReadyAdd(true);
    }).catch((e)=>{
      console.log(e);
      setReadyAdd(true);
    });
  }

  const getList = (e)=>{
    setReady(false);
    dispatch(getDictionariesList(selectedType)).then(res=>{
        
      setReady(true);
    }).catch((e)=>{
      console.log(e);
      setReady(true);
    });
  }

  const addHandler=(e)=>{
    if(e.target.id==newElement){
      setNewElement('')
    }else if(e.target.id!=newElement){
      setNewElement(e.target.id)
    }
  }

  const addaAdditionalHandler=(e)=>{
    if(e.target.id==newAdditionalElement){
      setNewAdditionalElement('')
      setSelectedAdditionalType('')
    }else if(e.target.id!=newAdditionalElement){
      setNewAdditionalElement(e.target.id)
      setSelectedAdditionalType(e.target.id)
      setForm({})
    }
  }

  const changeHandler = (e)=>{
    
    setForm({...form,[e.target.name]:e.target.value})
  }

  const saveAdd=(e)=>{
    let data={...form}
    data.category=newElement;
    dispatch(addDictionariesItem(data)).then(res=>{
        
      addHandler(e)
      getList();
      setForm({})
    })
  }
  const saveAddAdditional =(e)=>{
    let data={...form}
    data.category='districts';
    data.regionId=newAdditionalElement;
    dispatch(addDictionariesItem(data)).then(res=>{
        
      // addaAdditionalHandler(e)
      setForm({})
      getAdditionalList();
    })
  }
  const saveAddAdditionalCategory =(e)=>{
    let data={...form}
    data.category=e.target.id;
    data.parentId=selectedAdditionalType;

    dispatch(addDictionariesItem(data)).then(res=>{
        
      // addaAdditionalHandler(e)
      setForm({})
      getList();
    })
  }

    const editClick =(e)=>{
      setEditElement(e.target.id);
      setForm({})
    }

    const saveEdit = (e)=>{
      
      let data={...form};
      data.category=selectedType;
      data.id = e.target.id;
      dispatch(editDictionariesItem(data)).then(res=>{
        setEditElement('');
        getList();
        setForm({})
      }).catch((e)=>{
        console.log(e);
        setReady(true);
      });
    }
    const saveEditAdditional = (e)=>{
      
      let data={...form};
      data.category='districts';
      data.id = e.target.id;
      dispatch(editDictionariesItem(data)).then(res=>{
        setEditElement('');
        getList();
        setForm({});
        getAdditionalList();
      }).catch((e)=>{
        console.log(e);
        setReady(true);
      });
    }
    const saveEditAdditionalSub =(e)=>{
      let data={...form};
      data.category='exercise-subcategories';
      data.id = e.target.id;
      data.parentId=e.target.slot;
      dispatch(editDictionariesItem(data)).then(res=>{
        setEditElement('');
        getList();
        setForm({});
      }).catch((e)=>{
        console.log(e);
        setReady(true);
      });
    }
    
    const deleteClick=(e)=>{
      setDeleteModal(true);
      setDeleteElement(e.target.id);
    }
    const  deleteAdditionalClick=(e)=>{
      setDeleteAdditionalModal(true);
      setDeleteElement(e.target.id);
    }
    const deleteSubmit=(e)=>{
      let data={}
      data.category=selectedType;
      data.id = deleteElement;
      dispatch(deleteDictionariesItem(data)).then(res=>{
        setDeleteModal(false);
        getList();
      })
  
    }
    const deleteAdditionalSubmit=(e)=>{
      let data={}
      data.category='districts';
      data.id = deleteElement;
      dispatch(deleteDictionariesItem(data)).then(res=>{
        setDeleteAdditionalModal(false);
        getList();
        getAdditionalList();
      })
    }
    const deleteAdditionalSubSubmit=(e)=>{
      let data={}
      data.category='exercise-subcategories';
      data.id = deleteElement;
      dispatch(deleteDictionariesItem(data)).then(res=>{
        setDeleteAdditionalModal(false);
        getList();
        getAdditionalList();
      })
    }
   
  


        return(
            <>
          <h1>Довідники</h1>
          <div className="pageContentHeaderDivider">&nbsp;</div>
          <div className="dictionaryHeaderWithButton" id='global-user-roles'  onClick={clickHandler}>
            <h2 id='global-user-roles' className={selectedType==='global-user-roles'&&'active'} onClick={clickHandler}>global-user-roles</h2>
            {/* <button id='global-user-roles' onClick={addHandler} className="secondaryButtonIconTextDefault">
             Додати Елемент довідника
            </button> */}
          </div>

          {selectedType==='global-user-roles' && <div className="dictAllRowsContainer">
            {!ready && <div style={{'position':'relative'}}>  <div style={{'position':'absolute','top':'50%','left':'50%'}}>
            <Loader/>
           </div> </div>}

            {dictionaries.length>0 ?  
            <>
              {dictionaries.map(el=>{
                return(
                  <div className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                     {editElement!==el.id?<div className="dictRowValue">{el.title}</div>
                      : <div className="dictRowValue"><div className="apInputText">
                          <input type="text" id={el.id} name='title' value={form.title?form.title:el.title} onChange={changeHandler}/>
                        </div></div>}
                        {editElement!==el.id?<div className="dictRowValue">{el.type}</div>
                      : <div className="dictRowValue"><div className="apInputText">
                          <input type="text" id={el.id} name='type' value={form.type?form.type:el.type} onChange={changeHandler}/>
                        </div></div>}
                    </div>
                    <div className="dictRowButtonsContainer">
                     {editElement!==el.id ?<button id={el.id} onClick={editClick} className="tetriaryButtonIconDefault"></button>
                      :<button onClick={saveEdit}  id={el.id} name='global-user-roles' className="tetriaryButtonIconDefault"></button>}
                      {editElement===el.id && <button onClick={()=>{setEditElement('')}}  className="tetriaryButtonIconDefault"></button>}
                    </div>
                  </div>
                )
              })}
            </>
            :<div className="dictRowEmpty">
              Довідник не заповнено
            </div> }
          </div>}

          <div className="dictionaryHeaderWithButton" id='chat-types'  onClick={clickHandler}>
            <h2 id='chat-types' className={selectedType==='chat-types'&&'active'} onClick={clickHandler}>chat-types</h2>
            {/* <button id='chat-types' onClick={addHandler} className="secondaryButtonIconTextDefault">
             Додати Елемент довідника
            </button> */}
          </div>
          {selectedType==='chat-types' && <div className="dictAllRowsContainer">
            {!ready &&   <div style={{'position':'relative'}}>  <div style={{'position':'absolute','top':'50%','left':'50%'}}>
            <Loader/>
           </div> </div>}

            {dictionaries.length>0 ?  
            <>
              {dictionaries.map(el=>{
                return(
                  <div className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                     {editElement!==el.id?<div className="dictRowValue">{el.title}</div>
                      : <div className="dictRowValue"><div className="apInputText">
                          <input type="text" id={el.id} name='title' value={form.title?form.title:el.title} onChange={changeHandler}/>
                        </div></div>}
                        {editElement!==el.id?<div className="dictRowValue">{el.type}</div>
                      : <div className="dictRowValue"><div className="apInputText">
                          <input type="text" id={el.id} name='type' value={form.type?form.type:el.type} onChange={changeHandler}/>
                        </div></div>}
                    </div>
                    <div className="dictRowButtonsContainer">
                     {editElement!==el.id ?<button id={el.id} onClick={editClick} className="tetriaryButtonIconDefault"></button>
                      :<button onClick={saveEdit}  id={el.id} name='chat-types' className="tetriaryButtonIconDefault"></button>}
                      {editElement===el.id && <button onClick={()=>{setEditElement('')}}  className="tetriaryButtonIconDefault"></button>}
                    </div>
                  </div>
                )
              })}
            </>
            :<div className="dictRowEmpty">
              Довідник не заповнено
            </div> }
          </div>}




          <div className="dictionaryHeaderWithButton" id='message-types'  onClick={clickHandler}>
            <h2 id='message-types' className={selectedType==='message-types'&&'active'} onClick={clickHandler}>message-types</h2>
            {/* <button id='message-types' onClick={addHandler} className="secondaryButtonIconTextDefault">
             Додати Елемент довідника
            </button> */}
          </div>
          {selectedType==='message-types' && <div className="dictAllRowsContainer">
            {!ready &&   <div style={{'position':'relative'}}>  <div style={{'position':'absolute','top':'50%','left':'50%'}}>
            <Loader/>
           </div> </div>}

            {dictionaries.length>0 ?  
            <>
              {dictionaries.map(el=>{
                return(
                  <div className="dictRowNormal">
                    <div className="dictRowValuesContainer">
                     {editElement!==el.id?<div className="dictRowValue">{el.title}</div>
                      : <div className="dictRowValue"><div className="apInputText">
                          <input type="text" id={el.id} name='title' value={form.title?form.title:el.title} onChange={changeHandler}/>
                        </div></div>}
                        {editElement!==el.id?<div className="dictRowValue">{el.type}</div>
                      : <div className="dictRowValue"><div className="apInputText">
                          <input type="text" id={el.id} name='type' value={form.type?form.type:el.type} onChange={changeHandler}/>
                        </div></div>}
                    </div>
                    <div className="dictRowButtonsContainer">
                     {editElement!==el.id ?<button id={el.id} onClick={editClick} className="tetriaryButtonIconDefault"></button>
                      :<button onClick={saveEdit}  id={el.id} name='message-types' className="tetriaryButtonIconDefault"></button>}
                      {editElement===el.id && <button onClick={()=>{setEditElement('')}}  className="tetriaryButtonIconDefault"></button>}
                    </div>
                  </div>
                )
              })}
            </>
            :<div className="dictRowEmpty">
              Довідник не заповнено
            </div> }
          </div>}









        </>
        )
}

export default DirectoryPage