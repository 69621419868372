import {
    CREATE_USER,
    RETRIEVE_USERS,
    UPDATE_USER,
    UPDATEPASSWORD_FAIL,
    UPDATEPASSWORD_SUCCESS,
    GET_CODE,
    GETCURRENTUSER,
    CHANGEUSER,
    DELETEUSER_SUCCESS,
    DELETEUSER_FAIL
  } from "./types";
  
  import UsersDataService from "../services/user.service";
  export const editRole = (object) => async (dispatch) => {
    try {
      const res = await UsersDataService.change(object);
      
      dispatch({
        type: CHANGEUSER,
        payload: res,
      });
      
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  };
  export const createUser = (object) => async (dispatch) => {
    try {
      const res = await UsersDataService.create(object);
   
      dispatch({
        type: CREATE_USER,
        payload: res.data,
      });

        
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  };



  export const retrieveUsers = (data) => async (dispatch) => {
             
    dispatch({
              type: RETRIEVE_USERS,
              payload: data.result,
            });
    };
  
  export const updateUser = (data) => async (dispatch) => {
    try {
      const res = await UsersDataService.update(data);
          
      dispatch({
        type: UPDATE_USER,
        payload: data,
      });
  
      return res;
    } catch (err) {
      return err.response.data;
    }
  };

  export const   applyForCoordinator  = (data) => async (dispatch) => {
    try {
      const res = await UsersDataService.applyForCoordinator();
          
      dispatch({
        type: UPDATE_USER,
      });
      return res;
    } catch (err) {
      return err.response.data;
    }
  };




  export const deactivateUser = (data) => async (dispatch) => {
    try {
      const res = await UsersDataService.deactivateUser(data);
      dispatch({
        type: UPDATE_USER,
        payload: data,
      });
  
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  };
  
  export const updatePassword=(id,data)=>(dispatch)=>{
    let res = UsersDataService.updatePassword(id,data).then(
      (data)=>{
     dispatch({
       type: UPDATEPASSWORD_SUCCESS,
       payload: {currentUser: data},
     });
     return Promise.resolve();
    },(error) => {         
      dispatch({
        type: UPDATEPASSWORD_FAIL,
        payload: {error: error.response},
      });

      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return error.response;
    }
  );
  return res;
}

export const deleteUser=(data)=>(dispatch)=>{
  let res = UsersDataService.deleteUser(data).then(
    (data)=>{
   dispatch({
     type: DELETEUSER_SUCCESS,
     payload: data,
   });
   return Promise.resolve();
  },(error) => {
       
    dispatch({
      type: DELETEUSER_FAIL,
      payload: {error: error.response},
    });
    return error.response;
  }
);
return res;
}


  export const getUser= (id) =>(dispatch) => {
      let res = UsersDataService.getById(id).then(
       (data)=>{
      dispatch({
        type: RETRIEVE_USERS,
        payload: {current: data},
      });
      return data;
    },(error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        return Promise.reject();
    }
  );
  return res;
};

export const getCurrentUser= (id) =>(dispatch) => {
  let res = UsersDataService.getById(id).then(
  (data)=>{
    
    dispatch({
      type:GETCURRENTUSER,
      payload: {currentUser: data},
    });
  return data;
  },(error) => {
    
    const message =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString();
    return Promise.reject();
  }
);
  return res;
};



  export const getCode = (data) => async (dispatch) => {
    try {
      const res = await UsersDataService.getCode(data);

      dispatch({
        type: GET_CODE,
        payload: res.data,
      });
      return res

    } catch (err) {
      console.log(err);
    }
  };

  export const getPhoneCode = (data) => async (dispatch) => {
    try {
      const res = await UsersDataService.getPhoneCode(data);
      dispatch({
        type: GET_CODE,
        payload: res,
      });
      return res;

    } catch (err) {
      console.log(err);
    }
  };
  export const verifyMail = (data) => async (dispatch) => {
    try {
      const res = await UsersDataService.verifyMail(data);

      dispatch({
        type: GET_CODE,
        payload: res.data,
      });

    } catch (err) {
      console.log(err);
    }
  };

  export const verifyPhone = (data) => async (dispatch) => {
    try {
      const res = await UsersDataService.verifyPhone(data);

      dispatch({
        type: GET_CODE,
        payload: res.data,
      });
      return res
    } catch (err) {
      console.log(err);
    }
  };


