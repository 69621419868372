import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setMessagesClear } from '../../redux/actions/messages';
import MessageItem from './MessageItem/MessageItem';
import Upload from './Uploud/Upload';
const ChatPage = (props)=>{
    const history=useHistory();
    const [edit, setEdit] = useState({});
    const currentChatId = useParams().id;
    const {messages} = useSelector(state=>state);
    const [heightData,setHeightData]=useState(0);
    const [ready,setReady]=useState(false);
    const [ready2,setReady2]=useState(false);
    const [form,setForm]=useState({})
    const {currentChatroom}=useSelector(state=>state);
    const {currentUser}=useSelector(state=>state.currentUser);
    const [visible, setVisible] = useState(false)
    const dispatch=useDispatch()
    const messagesEndRef = useRef(null);



    // function sendFile(fileArr) {
    //     var file = fileArr;
    //     const slice = file.slice(0, 10485760);
    //     var reader = new FileReader();
    //     reader.loadend = function() {
    //     }
    //     reader.onload = function(e) {
    //         const int8Array = new Int8Array(reader.result);
    //         const data=[];
    //         int8Array.forEach(element => {
    //             data.push(element);
    //         });

    //         // rawData = e.target.result;
    //         let wsData={
    //             action: "fileChunk",
    //             data:{
    //                 chunkId: "1", //each chunk of specific file must have THE SAME chunkId. It’s better to do chunkId with current unix time + few random signs
    //                 fileName:  fileArr.name,
    //                 chunkIndex: "0", //current index of chunk. Number must be within [0, totalChunk-1]
    //                 totalChunk: "1", //General amount of chunks 
    //                 fileTypeId: "1", //all file types here 
    //                 filePurposeTypeId: "2", //all file purpose types here
    //                 fileBody: data, //main filling of chunk (as rule it’s size is 10mb)
    //             }
    //         }
    //         props.ws.current.send(JSON.stringify(wsData));
            
    //         alert("the File has been transferred.")
    //     }
    //     reader.readAsArrayBuffer(slice);
    // }




    // const attachClick=(event)=>{
    //     for(let i=0; i<event.target.files.length; i++ ){
    //         sendFile( event.target.files[i])
    //     }
       

    //     // let obj = event.target.files[0];
    //     // let wsData={}
    //     // wsData={
    //     //     action: "fileChunk",
    //     //     data:{
    //     //         chunkId: "1", //each chunk of specific file must have THE SAME chunkId. It’s better to do chunkId with current unix time + few random signs
    //     //         fileName:  event.target.files[0].name,
    //     //         chunkIndex: "0", //current index of chunk. Number must be within [0, totalChunk-1]
    //     //         totalChunk: "1", //General amount of chunks 
    //     //         fileTypeId: "1", //all file types here 
    //     //         filePurposeTypeId: "2", //all file purpose types here
    //     //         fileBody: event.target.files[0], //main filling of chunk (as rule it’s size is 10mb)
    //     //     }

    //     // }
    //     // props.ws.current.send(JSON.stringify(wsData))
    // }

    const getData=(height,type)=>{

        let wsData={}

        if(height && !type){
            wsData ={
                action: "getMessageList",
                data:{
                    chatId: currentChatId,
                    messageId: messages[0].id,
                    messageListType: '0', // all messageListTypes here
                    limit: 30,
                }
            }
            // scrollToCurrent(height)
        }
        else if (type && currentChatroom.totalUnreadMessages>0  ){
            wsData ={
                    action: "getMessageList",
                    data:{
                        chatId: currentChatId,
                        messageId: messages[messages.length-1].id,
                        messageListType: '2', // all messageListTypes here
                        limit:currentChatroom.totalUnreadMessages>30?30:currentChatroom.totalUnreadMessages,
                    }
                }
                // scrollToCurrent(height)
        }
        else {
            wsData={
                action: "getChatByIdWithMessages",
                data:{
                    chatId: currentChatId,
                    messageLimit:30
                }
            }
        }
        props.ws.current.send(JSON.stringify(wsData))
        setReady(true);

        // dispatch(getMessagesList(data)).then(res=>{
        //     setReady(true);
        //     if(!height){
        //         scrollToBottom()
        //     }else{
        //         scrollToCurrent(height)
        //     }
        //     // gettingData(res.items);
        // })
    }
    

    const initialFunc=()=>{
        if(!messages || messages[0]?.chatId!==currentChatId){
            getData()
        }
        // if(!ws.current || ws.current.readyState==3){
        //     connect()
        // }
        // ws.current.onclose = function(e) {
        //     console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        //     setTimeout(function() {
        //     setIsPaused(true);
        // }, 0);
        // };
    }

    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
    }


    


    // const sendClickFirstTime=(e)=>{
    //     e.preventDefault();
    //     localStorage.removeItem('newChat')
    //     let data = {
    //             "action": "initConversation",
    //             "data":{
    //                 "toUserId": user.id,
    //                 "text":form.newMsg
    //             } 
    //     }
    //     setForm({newMsg:''})
    //     // if (!ws.current || ws.current.readyState==3){
    //     //     connect(JSON.stringify(data))
    //     // }
    //     // else{
    //         props.ws.current.send(JSON.stringify(data));
    //     // }
       

    // }
    const deleteClick=(e)=>{
        e.preventDefault();
        let data={
            "action":"deleteChat",
            "data":{
                "chatId":currentChatId
            }
        }
        props.ws.current.send(JSON.stringify(data))
        history.push('/home');
    }
    const setMuted=(e)=>{
        let data={
            "action":"switchNotification",
            "data":{
                "chatId":currentChatId
            }
        }
        props.ws.current.send(JSON.stringify(data))
    }
    const editSend =(e)=>{
        e.preventDefault();
        let data={
            action: 'changeChatMessage',
            data:{
                messageId:  form.editId,
                text: form.editMsg
            }
        }
        setForm({})
        props.ws.current.send(JSON.stringify(data));
        // scrollToBottom()
    }

    const sendClick=(e)=>{
        e.preventDefault();
        let data = {
            "action": "newChatMessage",
            "data":{
                "chatId": currentChatId,
                "text": form.newMsg,
                "messageTypeId": "AAAAAAA0",
            }
        }
        setForm({newMsg:''})
        props.ws.current.send(JSON.stringify(data));

        if(messages.length===1&&messages[0].messageType==='emptyChat'){
            let del={
                action: "deleteChatMessage",
                data:{
                    messageId: messages[0].id,
                    chatId: messages[0].chatId
                }
            }
            props.ws.current.send(JSON.stringify(del))
        }
        scrollToBottom()
    }

    const scrollToBottom =useCallback( () => {
        

        if(messagesEndRef.current && messagesEndRef.current.scrollHeight){
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
        else if(messagesEndRef &&  messagesEndRef.current){
            messagesEndRef.current.scrollTop = 0;
        }
        // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
      },[])

      

      const scrollToCurrent = (prev)=>{
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight-prev;
      }

    const toggleVisible = () => {

        const height = messagesEndRef.current.scrollHeight-messagesEndRef.current.scrollTop;
        console.log('height: ' + height);
        console.log('scrollTop: ' + messagesEndRef.current.scrollTop);
        console.log('scrollHeight: ' + messagesEndRef.current.scrollHeight);
        console.log('outerHeight: ' +window.outerHeight);
        setHeightData(height);

        if(((messagesEndRef.current.scrollTop <= 100 &&  messagesEndRef.current.scrollTop>99)||messagesEndRef.current.scrollTop===0)){
            getData(height);
        }else if( height<window.innerHeight && currentChatroom.totalUnreadMessages>0 && heightData!==height){
            getData(height,true);
            
        }

        if (height > window.outerHeight){
          setVisible(true)
        } 
        else if (height <= window.outerHeight){
          setVisible(false)
        }
    };


      useEffect(() => {
       if(!ready || messages && ((messages.length>0 && messages[0].chatId!==currentChatId))){
            initialFunc();
            setReady(true)
        }
        // else if(messages && messages.length===0 && document.location.pathname.split('/')[2]!=='0' && ready2){
        //     initialFunc();
        //     setReady(true)
        // }
        if(messages && heightData==0 && currentChatroom.totalUnreadMessages>5){
           
            scrollToCurrent(window.outerHeight)
        }
        else if(messages && heightData==0){
            scrollToBottom()
        }else if(messages && heightData>0){
            // console.log('/////////////////////');
            // const height = messagesEndRef.current.scrollHeight-messagesEndRef.current.scrollTop;
            // console.log('heightData', heightData)
            // console.log('height: ' + height);
            // console.log('scrollTop: ' + messagesEndRef.current.scrollTop);
            // console.log('scrollHeight: ' + messagesEndRef.current.scrollHeight);
            // console.log('outerHeight: ' +window.outerHeight);
            // console.log('/////////////////////');
            scrollToCurrent(heightData)
        }

    }, [currentChatId,messages]);

     

    if((!ready || !currentChatroom ||  !currentChatroom.iconColor)){
        return(
            <>
                Loading
            </>
        )
    }


    if(ready && currentChatroom &&  currentChatroom.iconColor){
        return (
        <>
            <div className='chatHeader' > 
                <div className='chatHeaderSub'>
                    <div className='icon' style={{'margin-right':'12px'}}><div className={currentChatroom.isFaworite?'faworite':'unfaworite'}></div></div>
                    <div className='avatar title2' style={{backgroundColor:`${currentChatroom.iconColor?
                        currentChatroom.type==='conversation'?
                         currentChatroom.iconColor.split('+')[0]==currentUser.nicknameColor?
                         currentChatroom.iconColor.split('+')[1]:
                         currentChatroom.iconColor.split('+')[0]:
                         currentChatroom.iconColor:'green'}`}}> 
                        {currentChatroom.type==='conversation'&& currentChatroom.title ? currentChatroom.title.split('+')[0]==currentUser.nickname?currentChatroom.title.split('+')[1][0].toUpperCase():currentChatroom.title.split('+')[0][0].toUpperCase() :currentChatroom.title[0].toUpperCase()}
                    </div>
                    <div className='statusType'>
                        { currentChatroom.type==='conversation'&&
                            <div className='online'></div>
                        }
                        { (currentChatroom.type==='group'&&  
                            currentChatroom.isPrivate) ? <div className='privateIcon'></div>:''
                        }
                        {(currentChatroom.type==='group'&&  
                            !currentChatroom.isPrivate) ? <div className='publicIcon'> </div>:''
                        }
                    </div>
                    {<div className='title3'>{currentChatroom.type==='conversation'? currentChatroom.title.split('+')[0]==currentUser.nickname?currentChatroom.title.split('+')[1]:currentChatroom.title.split('+')[0]:currentChatroom.title}</div>}
                    <div className='subIcon'>
                    {!currentChatroom.notificationOn?
                        <div className='mutedIcon' >
                        </div>:
                            <>
                            </>
                        }
                    </div>
                </div>
                <div>
                    <div onClick={setMuted}>{currentChatroom.notificationOn?'unmuted':'muted'}</div>
                    <button onClick={deleteClick}>del</button>
                </div>

               {/* {currentChatId!=='0'&& <div style={{'display':'inline-block','fontSize':'20px','fontWeight':'bold','margin-left':'20px'}}>{currentChatroom.type==='conversation'? currentChatroom.title.split('+')[0]==currentUser.nickname?currentChatroom.title.split('+')[1]:currentChatroom.title.split('+')[0]:currentChatroom.title}</div>}
                <div onClick={setMuted} style={{'display':'inline-block','margin-left':'300px'}}>{currentChatroom.notificationOn?'unmuted':'muted'}</div>
                <button onClick={deleteClick} style={{'display':'inline-block','margin-left':'20px'}}>Delete </button> */}
            </div>

            <div className='chatList' ref={messagesEndRef} style={{height:'inherit'}} onScroll={toggleVisible}>
                {messages && messages.map((el,index)=>{
                    return(

                        <>
                            {el.id===currentChatroom.lastReadMessageId && messages.length-1 !== index && !el.isMine && <div className='sysyemMsg caption1'>Непрочитанные сообщения</div>}
                            <MessageItem  key={el.id} ws={props.ws} el={el} height={heightData} messagesEndRef={messagesEndRef} setEdit={setEdit} setForm={setForm}></MessageItem>
                        </>
                    
                    )
                    }
                    
                )}
                {/* <div ref={messagesEndRef}/> */}
            </div>
            {!form.editId&&<form className='chatInputContainer' onSubmit={sendClick}>
                
                <Upload ws={props.ws} scrollToBottom={scrollToBottom}></Upload>

                <input type="text" placeholder='Сообщение...' className='chatInput' id='newMsg' name='newMsg' value={form.newMsg?form.newMsg:''} onChange={changeHandler}/>
                <div className='icon btn' >
                    <div className='sendIcon'  onClick={sendClick}> 
                    </div>
                </div>
                {/* <button type='submit' onClick={sendClick}>send</button> */}
            </form>}
            {form.editId&&  <form className='chatInputContainer' onSubmit={editSend}>
                <input type="text" className='chatInput' id={form.editId} name='editMsg' value={form.editMsg} onChange={changeHandler}/>
                {/* <button type='submit' onClick={sendClick}>send</button> */}
            </form>}

            
            {(visible||currentChatroom.totalUnreadMessages>0) && <button className='toggleDown' onClick={scrollToBottom} >
                {currentChatroom.totalUnreadMessages>0?currentChatroom.totalUnreadMessages:''}
            </button>}

        </>
    )
}

}


export default ChatPage;