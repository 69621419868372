import axios from "axios";

import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const uploadFile = (data) =>{
    // return axios.post(constants.URL + `/assets`,data,{ headers: {'Content-Type': 'multipart/form-data'} ,body: JSON.stringify(data)});
    
    let auth =authHeader();
    return axios({
        method: "post",
        url: constants.URL + `/uploads`,
        data: data,
        headers: { "Content-Type": "multipart/form-data", 'Authorization': auth.Authorization},
      })

}

export default {
    uploadFile,
}