export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const DONE_REFRESHING_TOKEN = "DONE_REFRESHING_TOKEN";
export const REFRESHING_TOKEN = "REFRESHING_TOKEN";
export const LOGOUT = "LOGOUT";
export const CODE_SUCCESS="CODE_SUCCESS";
export const CODE_FAIL ="CODE_FAIL";
export const RESTORE_SUCCESS="RESTORE_SUCCESS";
export const RESTORE_FAIL ="RESTORE_FAIL";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GETCURRENTUSER="GETCURRENTUSER";

export const CREATE_USER = "CREATE_USER";
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const GET_CODE ="GET_CODE"
export const UPDATEPASSWORD_SUCCESS = "UPDATEPASSWORD_SUCCESS";
export const UPDATEPASSWORD_FAIL = "UPDATEPASSWORD_FAIL";
export const CHANGEUSER = "CHANGEUSER";
export const DELETEUSER_SUCCESS = "DELETEUSER_SUCCESS";
export const DELETEUSER_FAIL = "DELETEUSER_FAIL";

export const DICTIONARIESLIST_SUCCESS = "DICTIONARIESLIST_SUCCESS";
export const DICTIONARIESLIST_FAIL = " DICTIONARIESLIST_FAIL";
export const ADDITIONALDICTIONARIESLIST_SUCCESS = "ADDITIONALDICTIONARIESLIST_SUCCESS";
export const ADDITIONALDICTIONARIESLIST_FAIL = " ADDITIONALDICTIONARIESLIST_FAIL";
export const DICTIONARIESDELETE_SUCCESS= "DICTIONARIESDELETE_SUCCESS";
export const DICTIONARIESDELETE_FAIL= "DICTIONARIESDELETE_FAIL";
export const DICTIONARIESEDIT_SUCCESS= "DICTIONARIESEDIT_SUCCESS";
export const DICTIONARIESEDIT_FAIL= "DICTIONARIESEDIT_FAIL";
export const DICTIONARIESADD_FAIL= "DICTIONARIESADD_FAIL";
export const DICTIONARIESADD_SUCCESS= "DICTIONARIESADD_SUCCESS";
export const DICTIONARIESITEM_SUCCESS= "DICTIONARIESITEM_SUCCESS";
export const DICTIONARIESITEM_FAIL= "DICTIONARIESITEM_FAIL";
export const DICTIONARIESFULLLIST_SUCCESS= "DICTIONARIESFULLLIST_SUCCESS";
export const DICTIONARIESFULLLIST_FAIL= "DICTIONARIESFULLLIST_FAIL";
export const FULLLIST_SUCCESS= "FULLLIST_SUCCESS";
export const FULLLIST_FAIL= "FULLLIST_FAIL";
export const LISTID_SUCCESS= "LISTID_SUCCESS";

export const UPLOADFILE_SUCCESS= "UPLOADFILE_SUCCESS";
export const UPLOADFILE_FAIL= "UPLOADFILE_FAIL";

export const LOG_SUCCESS = "LOG_SUCCESS";
export const LOG_FAIL = "LOG_FAIL";

export const FIREBASETOKENPUSH_SUCCESS = "FIREBASETOKENPUSH_SUCCESS";
export const FIREBASETOKENPUSH_FAIL = "FIREBASETOKENPUSH_FAIL";


export const CHATROOMLIST_SUCCESS = "CHATROOMLIST_SUCCESS";
export const CHATROOMLIST_FAIL = "CHATROOMLIST_FAIL";
export const CHATROOMDELETE_SUCCESS = "CHATROOMDELETE_SUCCESS";
export const CHATROOMDELETE_FAIL = "CHATROOMDELETE_FAIL";
export const CHATROOMEDIT_SUCCESS = "CHATROOMEDIT_SUCCESS";
export const CHATROOMEDIT_FAIL = "CHATROOMEDIT_FAIL";
export const CHATROOMADD_SUCCESS = "CHATROOMADD_SUCCESS";
export const CHATROOMADD_FAIL = "CHATROOMADD_FAIL";
export const CHATROOMITEM_SUCCESS = "CHATROOMITEM_SUCCESS";
export const CHATROOMITEM_FAIL = "CHATROOMITEM_FAIL";
export const CHATROOMREADED_SUCCESS = "CHATROOMREADED_SUCCESS";
export const CHATROOMITEMREADED_SUCCESS = "CHATROOMITEMREADED_SUCCESS";


export const MESSAGESLIST_SUCCESS = "MESSAGESLIST_SUCCESS";
export const MESSAGESLIST_FAIL = "MESSAGESLIST_FAIL";
export const MESSAGESDELETE_SUCCESS = "MESSAGESDELETE_SUCCESS";
export const MESSAGESDELETE_FAIL = "MESSAGESDELETE_FAIL";
export const MESSAGESEDIT_SUCCESS = "MESSAGESEDIT_SUCCESS";
export const MESSAGESEDIT_FAIL = "MESSAGESEDIT_FAIL";
export const MESSAGESADD_SUCCESS = "MESSAGESADD_SUCCESS";
export const MESSAGESADD_FAIL = "MESSAGESADD_FAIL";
export const MESSAGESITEM_SUCCESS = "MESSAGESITEM_SUCCESS";
export const MESSAGESITEM_FAIL = "MESSAGESITEM_FAIL";


export const MESSAGESADD_ANOTHERCHAT_SUCCESS = 'MESSAGESADD_ANOTHERCHAT_SUCCESS';

