import {
    MESSAGESLIST_SUCCESS,
    MESSAGESLIST_FAIL,
    MESSAGESDELETE_SUCCESS,
    MESSAGESDELETE_FAIL,
    MESSAGESEDIT_SUCCESS,
    MESSAGESEDIT_FAIL,
    MESSAGESADD_SUCCESS,
    MESSAGESADD_FAIL,
  }from "../actions/types";
  
  const initialState = [];
  
  export default function messagesReducer(messages = initialState, action) {
      const { type,list, item , val, id,scrollType} = action;
        
      switch (type) {
            case MESSAGESLIST_FAIL:
                return [];
            case MESSAGESLIST_SUCCESS:
                
                if(!scrollType || scrollType==='0'){
                    if(messages[0] && list.length>0 && list[0] &&  messages[0].chatId === list[0].chatId && messages[0].id!==list[0].id  ){
                        console.log('list '+list)
                        console.log('messages '+ messages)
                        let arr = [...list.reverse(), ...messages]
                        return arr
                    }else if(list.length === 0 && !val){
                        return messages
                    }else if(list.length === 0 && val){
                        return [];
                    }
                    else{
                        return list.reverse()
                    }
                }else if(scrollType==='2'&& messages.length>0 && list.length>0 && messages[messages.length-1].id<list[0].id){
                    let arr = [ ...messages,...list.reverse()]
                    return arr
                }else if(scrollType==='1'){
                    return list.reverse()
                }
                break;
            case MESSAGESEDIT_SUCCESS:
                    debugger
                if(id){
                    messages.forEach((el)=>{
                        if(el.id<=id.result.messageId){
                            el.isRead=true;
                        }
                    })
                }else{
                    messages.forEach((el,index)=>{
                        
                        if(el.id===item.result.id){
                             messages[index]=item.result
                        }
                    })
                }
                return  messages.slice(0);
            case MESSAGESDELETE_FAIL:
              return messages;
            case MESSAGESDELETE_SUCCESS:
               messages.forEach((el,index)=>{
                   if(el.id===item.result.deletedMessageId){
                        messages.splice(index,1)
                   }
               })
            return  messages.slice(0);
            case MESSAGESEDIT_FAIL:
              return messages;
            case MESSAGESADD_FAIL:
            return  messages;
            case MESSAGESADD_SUCCESS: 
             
                messages.push(item);
                return messages.slice(0);
            
          default:
            return messages;
      }
  }