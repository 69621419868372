import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "../../../redux/actions/auth";
import { useTranslation } from 'react-i18next';
import ChangeLng from '../../../components/ChangeLng/ChangeLng';


const LoginPage = ()=>  {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [form, setForm] = useState({initialState: {
        email:'', password: ''
    }})
    const [err, setErr]= useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {  isLoggedIn } = useSelector(state => state.auth);


    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
    }

    function handleRegister() {
        history.push("/registration");
    }

    function handleRestore() {
        history.push("/restore");
    }

    const signHandler = (e) => {
        e.preventDefault();
         
        setLoading(true);
        let data = {
            login:form.login,
            password:form.password,
            type: '0',   //Types here
            deviceId: window.clientInformation.productSub,
            operatingSystem: window.clientInformation.platform

        }
          dispatch(login(data))
            .then((res) => { 
                 
                if(typeof res === "string"){
                    setErr(true)
                }else{
                    window.location.reload();
                }
            })
            .catch((e) => {
                 
                setErr(true)
              console.log(e)
            //   setLoading(false);
            });
      };

        return(
        <div  className='loginBlock' >
            <form  className='loginForm'>
                    <div className='loginLogo'></div>

                <label className='input text1'>
                    <input  className={err?'':'inputDeafult'} type="text" name="login" placeholder={t('auth_login_placeholder')} onChange={changeHandler} value={form.login?form.login:''} />
                    {err&& <span className=''>{t("alert_update_profile_invalid_email_title" )}</span>}
                </label>
                <label className='input text1'>
                    <input className={err?'':'inputDeafult'} type="text" name="password" required onChange={changeHandler} placeholder={t('auth_password_placeholder')}  value={form.password?form.password:''}/>
                    {err && <span className=''>{t("alert_authorization_error_title" )}</span>}
                </label>
                    <button className='button button-text' type="submit"  onClick={signHandler}>{t('auth_logIn')}</button>
                <div className='aditionalLogin text1'>
                    <div>{t('auth_NoAccount')} <a className='link'  onClick={handleRegister}>{t('auth_registr')}</a></div>
                    <div></div>
                    <div>   <ChangeLng></ChangeLng></div>
                   
                    {/* <a className='' onClick={handleRestore}>{t('auth_forgot_password_title')}</a> */}
                 
                </div>
             
            </form>
        </div>
    )


}

export default LoginPage;