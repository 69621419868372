import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { addChatroomItem } from "../../redux/actions/chatroom";
import { retrieveUsers } from "../../redux/actions/users";


const CreateChatModal= (props) =>{
    const [ready,setReady]=useState(false);
    const dispatch = useDispatch();
    const [form,setForm]=useState({});
    const [privat,setPrivat]=useState(false);
    const [read,setRead]=useState(false);
    const [userList,setUserList]=useState([]);
    const {users} = useSelector(state=>state);
    const history = useHistory()

    let initFunction =()=>{
        setReady(true)
    }


    const changeHandler=(event)=>{
        setForm({...form, [event.target.name]: event.target.value});
    }
    const changeHandlerSearch = event =>{
        setForm({...form, [event.target.name]: event.target.value})
        if(event.target.value.length>2){
            let data={    
                "action": "getUserList",
                "data":{
                    "limit": 20,
                    "chatId": null, // chatId: null - without chat filtering
                    "all": event.target.value, // all: null - without this filtering
                    "offset": 0
                }
            }
            props.ws.current.send(JSON.stringify(data));
        }else if(event.target.value.length<3){

        
        }

    }

    const addUser=(e)=>{
        const list = [...userList];
        let listId=[]
        if(form.userIdArray){
            listId= [...form.userIdArray]        
        }
        
        if(list.indexOf(e.target.id+'/'+e.target.accessKey)!==-1){
            list.splice(list.indexOf(e.target.id+'/'+e.target.accessKey),1)
            listId.splice(list.indexOf(e.target.id+'/'+e.target.accessKey),1)
        }else{
            list.push(e.target.id+'/'+e.target.accessKey);
            listId.push(e.target.id);
        }
    
        let data = form;
        data.userIdArray= [...listId];
        setForm(data);
        setUserList(list);

    }

    const closeClick=(e)=>{
        e.preventDefault();
        props.setModal(false);
    }

    const createClick = (e)=>{
        e.preventDefault();
        let data =
                {
                    action: "newChat",
                    data:{
                        title:  form.title,
                        "userIdArray": form.userIdArray,
                    }
                }
        if(read){
            data.data.typeId="AAAAAAA3"
        }else if(!read){
            data.data.typeId="AAAAAAA2"
        }

        if(privat){
            data.data.isPrivate='1'
        }else if(!privat){
            data.data.isPrivate='0'
        }
            
            props.ws.current.send(JSON.stringify(data))
            props.setModal(false);
    }
    useEffect(() => {
        if(!ready){
            initFunction();
        }
    }, [userList.length,userList,users]);


    return(
        <div className="modal">
                <div onClick={()=>setPrivat(!privat)}>Приватний канал  {privat?'selected':''}</div>
                <div onClick={()=>setRead(!read)}>Канал тільки для читання {read?'selected':''}</div>
                {/* <div>Id канал</div> */}
                <input type='text' name="title" placeholder="Ім'я каналу мінімум 3 символи" onChange={changeHandler}></input>
                <br />
                <input  placeholder="Запросити користувачей" name='search' onChange={changeHandlerSearch} value={form.search}></input>
                 {userList && userList.length>0 && userList.map(el=>{
                     return(
                        <div key={el.split('/')[0]} id={el.split('/')[0]} accessKey={el.split('/')[1]} onClick={addUser}>
                          {el.split('/')[1]} selected
                        </div>
                      )
                 })

                 }
                 {users &&users.items&& users.items.map(el=>{
                      if(userList.indexOf(el.id+'/'+el.login)!==-1){
                      }else{
                        return(
                            <div key={el.id} id={el.id} accessKey={el.login} onClick={addUser}>
                              {el.login}
                            </div>
                          )
                        }     
                  })
                  }
                <br />
                <button  onClick={createClick} disabled={(!form.title||(form.title && form.title.length<3))}>Создать</button>
                <br />
                <button  onClick={closeClick}>Відмінити</button>
        </div>
    )

}


export default CreateChatModal;