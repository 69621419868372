import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import ChangeLng from '../../../components/ChangeLng/ChangeLng';
import { restore } from '../../../redux/actions/auth';

const RestorePage = ()=>{
    const history = useHistory();
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();
    const [err, setErr]= useState(false);
    const [errArr, setErrArr]= useState([]);
    const [form, setForm] = useState({initialState: {
        login:'', password: '', repassword:''
    }})
    const restoreHandler =(e)=>{
        e.preventDefault();
        dispatch(restore(form)).then((res)=>{
            if(res && res.errors && res.errors.length>0){
                setErrArr(res.errors);
                setErr(true)
            }else{
                    history.push("/login");
            }
        }).catch((e) => {
            
       });

    }

    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
    }
    function backClick() {
        history.push("/login");
    }
    return(
        <>
         <div  className='loginBlock' >
            <form  className='loginForm'>
                <label className=''>
                    <input className={err?'':'inputDeafult'} type="text" name="login" placeholder={t('change_psw_login_placeholder')} onChange={changeHandler}/>
                    {err&& <span className=''>{t("alert_update_profile_invalid_email_title" )}</span>}
                </label>
                <label className=''>
                    <input className={err?'':'inputDeafult'} type="password" name="password" required onChange={changeHandler} placeholder={t('auth_login_password_placeholder')}  value={form.password?form.password:''}/>
                    {err && <span className=''>{t("alert_authorization_error_title" )}</span>}
                </label>
                <label className=''>
                    <input className={err?'':'inputDeafult'} type="password" name="repassword" required onChange={changeHandler} placeholder={t('change_psw_repassword_placeholder')}  value={form.repassword?form.repassword:''}/>
                    {err && <span className=''>{t("alert_authorization_error_title" )}</span>}
                </label>
                <button className='' type="submit" disabled={!form.login || !form.password || !form.repassword || (form.password && form.password.length==0) || (form.repassword && form.repassword.length==0 )|| (form.password!==form.repassword)} onClick={restoreHandler}>{t('auth_signup_button_register')}</button>
                <br />
                <a className='' onClick={backClick}>{t('auth_login_button_login_title')}</a>
                <ChangeLng></ChangeLng>
            </form>
        </div>



        </>

    
    )

}



export default RestorePage;