import axios from "axios";

import constants from '../../constants/constant.json';
import authHeader from "./auth-header";


const getMessagesList = (data)=>{
      
    return axios.get(constants.URL + `/chats/${data.chatId}/messages?offset=${data.offset}&limit=${data.limit}`,{ headers: authHeader()}).then((response) => { 
        return response.data;})
    .catch((e)=>{
        console.log(e);
    });
}

const deleteMessagesItem = (data)=>{
    return axios.delete(constants.URL + `/chats/${data.chatId}/messages/${data.id}`,{ headers: authHeader()})
    .then((response) => { 
          
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}
const editMessagesItem = (data)=>{
    let body
    body = {title:data.title};

    return axios.put(constants.URL + `/chats/${data.chatId}/messages/${data.id}`,{...data},{ headers: authHeader()})
    .then((response) => { 
          
        return response.data})
    .catch((e)=>{
        console.log(e);
    });
}

const createMessagesItem = (data)=>{   
    return axios.post(constants.URL +`/api/v1/chats/${data.chatId}/messages`,{...data},{ headers: authHeader()})
    .then((response) => { 
        return response.data})
    .catch((e)=>{
          
        console.log(e);
    });
}

const getMessagesItem = (data)=>{
    return axios.get(constants.URL + `/api/v1/chats/${data.chatId}/messages/${data.id}`) .then((response) => { 
        return response.data;})
    .catch((e)=>{
           
        console.log(e);
    });
}



export default{
    getMessagesList,
    deleteMessagesItem,
    editMessagesItem,
    createMessagesItem,
    getMessagesItem,
}