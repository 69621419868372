import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { retrieveUsers } from "../../redux/actions/users";

import Pagination from '../../components/Paggination/Paggination'
import { useHistory } from "react-router";
import Loader from "../../components/Loader/Loader";


const AdminUserPage = ()=>{
    const dispatch = useDispatch();
    const [ready,setReady]=useState(false);
    const {users} = useSelector(state=>state);
    const[filterArr,setFilterArr]=useState({})
    const {listId} = useSelector(state=>state);
    const history = useHistory();


    const initFunction=(page=1,filter)=>{
        let data={};
        dispatch(retrieveUsers(data)).then(res=>{
            setReady(true)
        })
      }


    useEffect(()=>{
        if(localStorage.getItem('usersFilter')){
          let filter =JSON.parse(localStorage.getItem('usersFilter'))
          initFunction(1,filter);
          setFilterArr(filter);
        }else{
          initFunction();
        }
      },[])

      if(!ready){
          return(
              <>
                <Loader></Loader>
              </>
          )
      }


if(ready){return(
<>
    <div className="dictAllRowsContainer">
    {users &&users.items&& users.items.map(el=>{
      return(
        <div key={el.id} className="usersRowNormal">
          {el.login} {el.roleId} 
        </div>
      )
    })
    }
  </div>
</>
)}


}


export default AdminUserPage;