import {
  MESSAGESITEM_SUCCESS,
  MESSAGESITEM_FAIL,
}from "../actions/types";

const initialState = [];

export default function currentMessagesReducer(messages = initialState, action) {
    const { type,list } = action;
      
    switch (type) {
          case MESSAGESITEM_SUCCESS:
            return [...list];
            case MESSAGESITEM_FAIL:
          return messages;
        default:
          return messages;
    }
}