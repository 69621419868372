import React from "react";

const Loader = ()=>{

return(
    <div className='spinner'>
<div></div><div></div><div></div><div></div></div>

)

}


export default Loader;