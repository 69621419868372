import{
    FIREBASETOKENPUSH_SUCCESS,
    FIREBASETOKENPUSH_FAIL,
} from './types';


import FirebaseService from '../services/firebase.service';


export const addPushToken = (data)=>(dispatch)=>{

    return FirebaseService.addPushToken(data).then(
        (response)=>{
            try{
           dispatch({
               type:FIREBASETOKENPUSH_SUCCESS,
               payload:response,
           });
           return response
           }
           catch{
                return Promise.reject();
           }
       },
       (error)=>{
           dispatch({
               type: FIREBASETOKENPUSH_FAIL,
             });
           console.log(error);
                
             return Promise.reject();
       }
    )


}