import React from "react";



const HomePage = ()=>{

    return(
        <div>
            <h1>Welcome to WeWe Web version!</h1>
            <div>The native mobile app, WeWe, for Android and iOS is available from a <a href="https://play.google.com/store/apps/details?id=chat.wewe.android" target="_blank" >Google Play</a> and the <a href="https://apps.apple.com/ru/app/wewe-phone/id1386715295" target="_blank" >App Store</a>.</div>

            <div>WeWe – это коммерческий продукт, который работает только на Вас и передает сообщения только между абонентами.</div> 

            <div>WeWe защищает все передаваемые сообщения, файлы, видео и изображения по самым современным стандартам.</div> 

            <h6>Самая полная АНОНИМНОСТЬ</h6>

            <div> Каждый пользователь WeWe сам создает свое имя, свой профиль. Если Вам нужна поистине анонимная связь – пользуйтесь только WeWe. <br />
            Для режима полной анонимности нет никакой привязки к какому-либо идентификатору типа почта или номер телефона. Если Вы забудете свои данные, то они восстановлению не подлежат. <br />
            Вы можете указать почту для восстановления данных и использовать WeWe как полноценный телефон и для случаев предотвращения потери своих идентификационных данных.
            </div>

            <h6>Самая защищенная передача данных</h6>

            <div> Применяются только современные методы защиты передаваемых данных, используемые в самых надежных системах передачи данных.
            </div>

            <h6>Защита Ваших данных</h6>

            <div> Отсутствие скриптов и других исполняемых элементов на стороне Вашего ПК полностью защищает Вас от вирусов и возможности их проникновения через WeWe.
            </div>

            <div> Приоритет – качество и безопасность. Мы отдали приоритет уничтожению информации вместо ее сохранения. Мы - коммерческая компания, работающая только в Ваших интересах. 
            При выборе методики сохранить или уничтожить, мы отдаем предпочтение удалению информации. Если Вы думаете о своей безопасности, то Вам нужен только WeWe.
            </div>

            <h6>Передовые методы уничтожения информации.</h6>

            <div> Если Вы удаляет Чат у себя, то он также полностью удалится и на стороне Вашего абонента без вопросов.
            </div>       
        </div>
    )

}



export default HomePage;