import{
    DICTIONARIESLIST_SUCCESS,
    DICTIONARIESLIST_FAIL,
    ADDITIONALDICTIONARIESLIST_SUCCESS,
    ADDITIONALDICTIONARIESLIST_FAIL,
    DICTIONARIESDELETE_SUCCESS,
    DICTIONARIESDELETE_FAIL,
    DICTIONARIESEDIT_SUCCESS,
    DICTIONARIESEDIT_FAIL,
    DICTIONARIESADD_SUCCESS,
    DICTIONARIESADD_FAIL,
    DICTIONARIESITEM_SUCCESS,
    DICTIONARIESITEM_FAIL,
    FULLLIST_FAIL,
    LISTID_SUCCESS,
    FULLLIST_SUCCESS,
} from './types'

import DictionariesService from '../services/dictionaries.service';


export const getDictionariesList = (data)=>(dispatch)=>{
    
    return DictionariesService.getDictionariesList(data).then(
        (response)=>{
              
             try{
            dispatch({
                type:DICTIONARIESLIST_SUCCESS,
                list:response.items,
            });
            return response.items
            }
            catch{
            return Promise.reject();
            }
        },
        (error)=>{
            dispatch({
                type: DICTIONARIESLIST_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}

export const getAdditionalDictionariesList = (data)=>(dispatch)=>{
    
    return DictionariesService.getAdditionalDictionariesList(data).then(
        (response)=>{
              
             try{
            dispatch({
                type:ADDITIONALDICTIONARIESLIST_SUCCESS,
                list:response.districts,
            });
            return response.districts
            }
            catch{
            return Promise.reject();
            }
        },
        (error)=>{
            dispatch({
                type: ADDITIONALDICTIONARIESLIST_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}
export const getAdditionalCategoriesList = (data)=>(dispatch)=>{
    
    return DictionariesService.getAdditionalCategoriesList(data).then(
        (response)=>{
             try{
            dispatch({
                type:ADDITIONALDICTIONARIESLIST_SUCCESS,
                list:response,
            });
            return response
            }
            catch{
            return Promise.reject();
            }
        },
        (error)=>{
            dispatch({
                type: ADDITIONALDICTIONARIESLIST_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );

}



export const deleteDictionariesItem= (data)=>(dispatch)=>{
      
    return DictionariesService.deleteDictionariesItem(data).then(
        (response)=>{
              

            dispatch({
                type:DICTIONARIESDELETE_SUCCESS,
            });
              

        },
        (error)=>{
            dispatch({
                type: DICTIONARIESDELETE_FAIL,
              });
            console.log(error);
                 
              return Promise.reject();
        }
    );
}
    export const editDictionariesItem= (form)=>(dispatch)=>{
      
        return DictionariesService.editDictionariesItem(form).then(
            (response)=>{
                  
    
                dispatch({
                    type:DICTIONARIESEDIT_SUCCESS,
                });
                  
                return response

            },
            (error)=>{
                dispatch({
                    type: DICTIONARIESEDIT_FAIL,
                  });
                console.log(error);
                     
                  return Promise.reject();
            }
        );
    }


    export const addDictionariesItem= (form)=>(dispatch)=>{
      
        return DictionariesService.addDictionariesItem(form).then(
            (response)=>{
                  
                  
                dispatch({
                    type:DICTIONARIESADD_SUCCESS,
                    list:response
                });
                  
                return response

            },
            (error)=>{
                dispatch({
                    type: DICTIONARIESADD_FAIL,
                  });
                console.log(error);
                     
                  return Promise.reject();
            }
        );
    }


    export const getDictionariesItem = (data)=>(dispatch)=>{
        return DictionariesService.getDictionariesItem(data).then(
            (response)=>{
                  
                dispatch({
                    type:DICTIONARIESITEM_SUCCESS,
                    list:response,
                });
                  
                return response
    
            },
            (error)=>{
                dispatch({
                    type: DICTIONARIESITEM_FAIL,
                  });
                console.log(error);
                     
                  return Promise.reject();
            }
        );
    
    }


    export const getFullList = (data)=>(dispatch)=>{
    
        return DictionariesService.getFullList(data).then(
            (response)=>{
                // let map = new Map();
                // for (let [key, value] of Object.entries(response)) {
                //     value.forEach(element => {
                //         map.set(element.id,element.title);
                        
                //     });
                // }
                let dat ={}
                for (let [key, value] of Object.entries(response)) {
                    
                        value.forEach(element => {
                            dat[element.id]=element.title;
                        });
                }
                 try{
                dispatch({
                    type:FULLLIST_SUCCESS,
                    list:response,
                });
                dispatch({
                    type:LISTID_SUCCESS,
                    list:dat,
                });
                return response
                }
                catch{
                return Promise.reject();
                }
            },
            (error)=>{
                dispatch({
                    type: FULLLIST_FAIL,
                  });
                console.log(error);
                     
                  return Promise.reject();
            }
        );
    
    }