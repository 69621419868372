import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const register = (form) => {
  return axios.post(constants.URL + "/users", 
    {...form}
  ).then((response) => {
       
    return response;
  }).catch((e)=>{
          return e.response
  });
};

const restore = (data)=>{
    
  return axios.post(constants.URL + "/users/restore-password", {
    ...data
  }).then(res=>{
      
  })

}
const changePassword = (data)=>{
    
  return axios.post(constants.URL + `/users/${data.id}/change-password`, {
    ...data
  },{headers: authHeader()}).then(res=>{
      return res
  })

}

const getCode =(data)=>{
  let email = data.email;
     
  return axios.post(constants.URL + "/users/email", {
    email
  }).then(res=>{
  })
}

const refresh=(refreshToken)=>{
  let data = {refreshToken:refreshToken}
  return axios.post(constants.URL + "/auth/refresh",data,{headers: authHeader()})
  .then((response) => {
    if (response.data.token && response.data.refreshToken) {
      localStorage.setItem("userData", JSON.stringify(response.data));
    }
    return response.data;
  }).catch((e)=>{
    
    console.log(e);
    logout();
  });;
}

const login = (data) => {
   
  return axios
    .post(constants.URL + "/auth/login",{...data})
    .then((response) => {
      if (response.data.token && response.data.refreshToken) {
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
      return response.data;
    }).catch((e)=>{
      
      return e.response.data;
    });
};

const logout = () => {
  sessionStorage.clear();
  localStorage.removeItem("userData");
};

export default {
  register,
  login,
  logout,
  refresh,
  restore,
  changePassword,
  getCode,
};






