import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import currentUser from "./currentUser";
import dictionaries from "./dictionary";
import chatroom from "./chatroom";
import fullList from "./fullList";
import listId from "./listId";
import messages from "./messages";
import currentMessages from "./currentMessages";
import currentChatroom from "./currentChatroom";

export default combineReducers({
  auth,
  currentUser,
  users,
  chatroom,
  currentChatroom,
  messages,
  currentMessages,
  dictionaries,
  fullList,
  listId,
  });
