import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { logout } from '../../redux/actions/auth';
import { getChatroomListWs } from '../../redux/actions/chatroom';
import { retrieveUsers } from '../../redux/actions/users';
import CreateChatModal from '../CreateChatModal/CreateChatModal';
import UsersSearch from '../UsersSearch/UsersSearch';

const SideBar =(props)=>{
    const [ready,setReady]=useState(false);
    const [form,setForm]=useState({});
    const {currentUser}=useSelector(state=>state.currentUser);
    const {chatroom}=useSelector(state=>state);
    const history = useHistory();
    const {auth} = useSelector(state=>state);
    const dispatch=useDispatch();
    const [menuOpen,setMenuOpen]=useState(false);
    const [modal,setModal]=useState(false);
    const [openSearch,setOpenSearch]=useState(false);



   

    const initialFunc=()=>{
        let data={
            action: "getChatList",
            data:{
                limit: "20",
                offset: "0"
            }
        }
        console.log("SideBar",props.ws)
        props.ws.current.send(JSON.stringify(data));
        setReady(true)        // dispatch(getChatroomList(data)).then(res=>{
        // })
    }

    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
        if(event.target.value.length>2){
            let data={    
                "action": "getUserList",
                "data":{
                    "limit": 20,
                    "chatId": null, // chatId: null - without chat filtering
                    "all": event.target.value, // all: null - without this filtering
                    "offset": 0
                }
            }
            props.ws.current.send(JSON.stringify(data));
        }else if(event.target.value.length<3){
            
        
        }
    }

    const createClick=()=>{
        setModal(true);
    }

    const logOutClick=()=>{
      dispatch(logout())
      window.location.reload();
        // in future add socket disconnect
    }

    const homeClickHandler =()=>{
        history.push('/home')
    }

    const openMore=(e)=>{

    }

    useEffect(() => {
        if(!ready && currentUser && props.readyWs){
            initialFunc()
        }
        console.log(props.ws.current)
    },[currentUser,props.ws.current,props.readyWs]);

if(!ready){
    return(
        <>
            Loading
        </>
    )
}
    if(ready){

    return(
        <>
            <div className='sidebar'>
                {!openSearch&&<div className='sidebarHeader'>
                    <div className='avatar title2' style={{backgroundColor:`${currentUser.nicknameColor?currentUser.nicknameColor:'black'}`}}> 
                        {currentUser && currentUser.login[0].toUpperCase()}
                    </div>
                    <div className='sidebarOptions'>
                        <div className='icon' onClick={()=>setOpenSearch(true)}>
                            <div className='searchIcon' > 
                            </div>
                        </div>
                       <div className='icon' onClick={createClick}>
                            <div className='createIcon' >
                                
                            </div>
                        </div>
                        <div  className='icon' onClick={logOutClick}>
                            <div className='logoutIcon' >
                                    
                            </div>
                        </div>
                    </div>
                    
                </div>}

                {!openSearch&&<div className='sidebarSection' >
                
                    <div className='title4 '>Сеансы чата</div>
                    {chatroom && chatroom.length>0 && chatroom.map(el=>{
                    
                    return(
                        <NavLink key={el.id} className='sidebarLink' to={'/chat/'+el.id} activeClassName="sidebarActive"  onClick={(e)=>setMenuOpen(false)}>
                                <div className='avatar title2' style={{backgroundColor:el.iconColor}}> 
                                    { el.title[0].toUpperCase()}
                                </div>
                                <div className='title3'>{el.title}</div>
                                {el.lastMessage && <div className='sidebarLastMessage'>
                                    <div className='sidebarLastMessageText'>{el.lastMessage.isMine?"Вы":el.lastMessage.fromUser.login}:{el.lastMessage.text}</div>
                                    <div>{new Date(el.lastMessage.createdAt).toLocaleString().slice(0,-3)}</div>
                                </div>}
                                {el.totalUnreadMessages>0&&<div className='unreaded'>{el.totalUnreadMessages}</div>}
                                <div className='icon moreButoon' onClick={openMore}>
                                    <div className='moreIcon' >
                                    </div>
                                </div>
                        </NavLink>
                    )

                    })
                    }
                </div>}
                {openSearch &&
                <>
                    <div className='sidebarHeader'>
                        <div style={{display:'flex'}}>
                            <input className='sidebarSeacrh text1' type="text" name='search' placeholder='min 3 symbols' value={form.search} onChange={changeHandler} />
                            <div  className='icon sidebarSeacrhIcon' onClick={()=>setOpenSearch(false)}>
                                <div className='closeIcon' >
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='sidebarSection' >
                        <UsersSearch setOpenSearch={setOpenSearch}></UsersSearch>
                    </div>
                </>
                }
                {currentUser &&currentUser.roleId==='631AbA1f' && <div className='sidebarSection' >
                    <h5>Налаштування</h5>
                    <NavLink className='sidebarLink' to="/userlist" activeClassName="sidebarActive"  onClick={(e)=>setMenuOpen(false)}> 
                        UserList
                    </NavLink> 
                    <NavLink className='sidebarLink' to="/dictionary" activeClassName="sidebarActive"  onClick={(e)=>setMenuOpen(false)}> 
                        Dictionaries
                    </NavLink>
                </div>
                }
                

                <div className='sidebarFooter'>
                    <div className='icon sidebarLogo' onClick={homeClickHandler}></div>
                    <div>{(props.ws.current.readyState===1&&'connected')||(props.ws.current.readyState!==1&& <div onClick={props.connect()}>disconnected</div>)}</div>
                </div>
                
            </div>
            {modal && <CreateChatModal ws={props.ws} setModal={setModal}/>}

        </>

    )
}

}

export default SideBar;