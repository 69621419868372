import React from 'react';


const LoadingPage = ()=>{

return(
    <div>
    
         <div>
            <img src="" alt="" />
         </div>
         <div>
            WeWe
         </div>
         <div>
             Loading ...
         </div>
    
    
    </div>
)


}


export default LoadingPage;