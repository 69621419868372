import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import ChangeLng from '../../../components/ChangeLng/ChangeLng';
import { register } from '../../../redux/actions/auth';
import axios from "axios";

const RegistrationPage = ()=>{
    const history = useHistory();
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();
    const [err, setErr]= useState(false);
    const [errArr, setErrArr]= useState([]);
    const [form, setForm] = useState({initialState: {
        login:'', password: '', repassword:''
    }})
    const changeHandler = event =>{
        setForm({...form, [event.target.name]: event.target.value})
    }
    function backClick() {
        history.push("/login");
    }

    
const ipFunction =(e)=>{
            e.preventDefault();
  axios.get("https://ipinfo.io/json?token=6de5360645668e").then(resp=>{
    registrHandler(resp.data.ip)
  }).catch((e)=>{
    console.log(e);
  })
}


    const registrHandler = (data) => {
        form.ipaddress= data;
         
        dispatch(register(form)).then((res)=>{
            if(res && res.errors && res.errors.length>0){
                setErrArr(res.errors);
                setErr(true)
            }else{
                    history.push("/login");
            }
        }).catch((e) => {
            
       });


      };


    return(
        <>
         <div  className='loginBlock' >
            <form  className='loginForm'>
                <div className='loginLogo'></div>
                <label className='input text1'>
                    <input className={err?'':'inputDeafult'} type="text" name="login" placeholder={t('auth_login_placeholder')} onChange={changeHandler}/>
                    {err&& <span className=''>{t("alert_update_profile_invalid_email_title" )}</span>}
                </label>
                <label className='input text1'>
                    <input className={err?'':'inputDeafult'} type="text" name="nickname" placeholder={t('auth_nickname_placeholder')} onChange={changeHandler}/>
                    {err&& <span className=''>{t("alert_update_profile_invalid_email_title" )}</span>}
                </label>
                <label className='input text1'>
                    <input className={err?'':'inputDeafult'} type="password" name="password" required onChange={changeHandler} placeholder={t('auth_password_placeholder')}  value={form.password?form.password:''}/>
                    {err && <span className=''>{t("alert_authorization_error_title" )}</span>}
                </label>
                <label className='input text1'>
                    <input className={err?'':'inputDeafult'} type="password" name="repassword" required onChange={changeHandler} placeholder={t('auth_repassword_placeholder')}  value={form.repassword?form.repassword:''}/>
                    {err && <span className=''>{t("alert_authorization_error_title" )}</span>}
                </label>
                <button className='button button-text' type="submit" disabled={!form.login || !form.nickname || !form.password || !form.repassword || (form.password && form.password.length<3) || (form.repassword && form.repassword.length<3 )|| (form.nickname && form.nickname.length<3 )|| (form.login && form.login.length<3 )|| (form.password!==form.repassword)} onClick={ipFunction}>{t('auth_registration')}</button>
                <div className='aditionalLogin text1'>
                    <div>{t('auth_HasAccount')}  <a className='link' onClick={backClick}>{t('auth_logIn')}</a></div>
                    <div>   <ChangeLng></ChangeLng></div>
                   
                    {/* <a className='' onClick={handleRestore}>{t('auth_forgot_password_title')}</a> */}

                </div>
            </form>
        </div>



        </>

    )

}



export default RegistrationPage;