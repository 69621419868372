import React, { useEffect, useState } from "react";

import i18n from "../../i18n";

const ChangeLng = ()=>{
    const [language, setLanguage] = useState('');
 
    const handleOnclick=(e)=>{
      e.preventDefault();
      setLanguage(e.target.value);
      localStorage.setItem('i18nextLng',e.target.value)
      i18n.changeLanguage(e.target.value);
    }

    useEffect(()=>{
        setLanguage(localStorage.getItem('i18nextLng'))
    },[])

    return(
        <>
            <label  htmlFor=""> 
                {/* <span className=''>{language}</span> */}
                <select name='language' onChange={handleOnclick} >
                    <option selected={language==='ru'} value={'ru'}>ru</option>
                    <option selected={language==='de'} value={'de'}>de</option>
                    <option selected={language==='ua'} value={'ua'}>ua</option>
                    <option selected={language==='en'} value={'en'}>en</option>
                </select>
            </label>
        
        
        </>


    )


}



export default ChangeLng;