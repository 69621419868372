import {
    UPLOADFILE_SUCCESS,
    UPLOADFILE_FAIL,
  } from "./types";

  import FileService from "../services/file.service";

  export const uploadFile = (data)=> (dispatch) => {
    return FileService.uploadFile(data).then(
      (response) => {
           
          dispatch({
          type: UPLOADFILE_SUCCESS,
        });
          
        return response;
      },
      (error) => {
  
        dispatch({
          type: UPLOADFILE_FAIL,
        });
  
        return Promise.reject();
      }
    );
  }
  