import {
  CHATROOMITEM_SUCCESS,
  CHATROOMITEM_FAIL,
  CHATROOMEDIT_SUCCESS,
  CHATROOMEDIT_FAIL,
  CHATROOMITEMREADED_SUCCESS,
}from "../actions/types";

const initialState = [];

export default function currentChatroomReducer(chatroomItem = initialState, action) {
    const { type,item,id, status } = action;
      
    switch (type) {
          case CHATROOMITEM_SUCCESS:
            return item;
          case CHATROOMITEM_FAIL:
            return chatroomItem;
          case CHATROOMEDIT_SUCCESS:
            return chatroomItem;
          case CHATROOMITEMREADED_SUCCESS:
            let res = {...chatroomItem}
            if(id.result && chatroomItem.id===id.result.chatId && status==='decrease' && chatroomItem.totalUnreadMessages>0){
              --res.totalUnreadMessages;    
            }else if (chatroomItem.id===id.chatId &&  status==='increase' && !id.isMine){
                ++res.totalUnreadMessages;
            }
            return res
          case CHATROOMEDIT_FAIL:
            return chatroomItem;
          default:
            return chatroomItem;
    }
}