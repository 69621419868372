import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser, retrieveUsers } from "../../redux/actions/users";
import { useHistory } from 'react-router';
import Loader from '../Loader/Loader';


const UsersSearch =(props)=>{
    const dispatch = useDispatch();
    const [ready,setReady]=useState(false);
    const {users} = useSelector(state=>state);
    const[filterArr,setFilterArr]=useState({})
    const {listId} = useSelector(state=>state);
    const history = useHistory();

    const createRoomClick=(e)=>{
        debugger
            localStorage.setItem('newChat',JSON.stringify(users.items[e.target.id]));
            props.setOpenSearch(false)
            history.push('/createChat')
    }


    const initFunction=(page=1,filter)=>{
        let data={};
        dispatch(retrieveUsers(data)).then(res=>{
            setReady(true)
        })
      }

      useEffect(() => {
        if(!ready){
            setReady(true)

            // initFunction();
        }
      }, []);


    if(!ready){
        return(
            <>
              <Loader></Loader>
            </>
        )
    }

    return(
        <>
            {users &&users.items&& users.items.map((el,index)=>{
              return(
                <div className='sidebarLink' key={el.id} id={index} accessKey={el.chatId} onClick={createRoomClick}>
                <div className='avatar title2' style={{backgroundColor:`${el.nicknameColor}`}}> 
                   {el.nickname[0].toUpperCase()}
                </div>
                <div className='title3'>{el.nickname}</div>
            </div>
              )
            })
            }
        </>
    )
        
        
}

export default UsersSearch;


