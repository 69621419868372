import{
    CHATROOMLIST_SUCCESS,
    CHATROOMLIST_FAIL,
    CHATROOMDELETE_SUCCESS,
    CHATROOMDELETE_FAIL,
    CHATROOMEDIT_SUCCESS,
    CHATROOMEDIT_FAIL,
    CHATROOMADD_SUCCESS,
    CHATROOMADD_FAIL,
    CHATROOMITEM_SUCCESS,
    CHATROOMITEM_FAIL,
    MESSAGESLIST_SUCCESS,
    MESSAGESLIST_FAIL
} from './types'

import ChatroomService from '../services/chatroom.service';

    export const getChatroomListWs=(data)=>(dispatch)=>{
        dispatch({
            type: CHATROOMLIST_SUCCESS,
            list:data.items,
        });
        return data.items;
    }

    // export const getChatroomList = (data)=>(dispatch)=>{

    //     return ChatroomService.getChatroomList(data).then(
    //         (response)=>{
                
    //             try{
    //             dispatch({
    //                 type: CHATROOMLIST_SUCCESS,
    //                 list:response.items,
    //             });
    //             return response.items
    //             }
    //             catch{
    //             return Promise.reject();
    //             }
    //         },
    //         (error)=>{
    //             dispatch({
    //                 type:  CHATROOMLIST_FAIL,
    //             });
    //             console.log(error);
                    
    //             return Promise.reject();
    //         }
    //     );

    // }

    export const deleteChatroomItem= (data)=>(dispatch)=>{
                dispatch({
                    type: CHATROOMDELETE_SUCCESS,
                    item: data
                });  
    }
    export const editChatroomItem= (form)=>(dispatch)=>{
      
        return ChatroomService.editChatroomItem(form).then(
            (response)=>{
                  
    
                dispatch({
                    type: CHATROOMEDIT_SUCCESS,
                });
                  
                return response

            },
            (error)=>{
                dispatch({
                    type:  CHATROOMEDIT_FAIL,
                  });
                console.log(error);
                     
                  return Promise.reject();
            }
        );
    }

    export const addChatroomItemWs= (data)=>(dispatch)=>{
        dispatch({
            type: CHATROOMADD_SUCCESS,
            item:data
        });
        return data;
    }
    // export const addChatroomItem= (form)=>(dispatch)=>{
      
    //     return ChatroomService.createChatroomItem(form).then(
    //         (response)=>{
                  
                  
    //             dispatch({
    //                 type: CHATROOMADD_SUCCESS,
    //                 list:response
    //             });
                  
    //             return response

    //         },
    //         (error)=>{
    //             dispatch({
    //                 type:  CHATROOMADD_FAIL,
    //               });
    //             console.log(error);
                     
    //               return Promise.reject();
    //         }
    //     );
    // }


    // export const getChatroomItem = (data)=>(dispatch)=>{
    //     return ChatroomService.getChatroomItem(data).then(
    //         (response)=>{
                  
    //             dispatch({
    //                 type: CHATROOMITEM_SUCCESS,
    //                 item:response,
    //             });
                  
    //             return response
    
    //         },
    //         (error)=>{
    //             dispatch({
    //                 type:  CHATROOMITEM_FAIL,
    //               });
    //             console.log(error);
                     
    //               return Promise.reject();
    //         }
    //     );
    
    // }

    export const setCurrentChatWithMessage= (data)=>(dispatch)=>{
            if(data.status<400){
                dispatch({
                    type: CHATROOMITEM_SUCCESS,
                    item:data.result.chat,
                }); 
                dispatch({
                    type: MESSAGESLIST_SUCCESS,
                    list:data.result.messages,
                    val:'CurrentChat'
                })
            
            }
            else{
                dispatch({
                    type: CHATROOMITEM_FAIL
                }); 
                dispatch({
                    type: MESSAGESLIST_FAIL
                })

            }
           return data
    }

    
    export const setCurrentChat= (data)=>(dispatch)=>{
        
        if(data.status<400){
            dispatch({
                type: CHATROOMITEM_SUCCESS,
                item:data.result,
            }); 
        }else{
            dispatch({
                type: CHATROOMITEM_FAIL
            }); 
            dispatch({
                type: MESSAGESLIST_FAIL
            })

        }
            
            


            return data
}



