import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyAqKTUScZjsxyiayWUZZ8aM51vRcvNFrHA",
  authDomain: "weweandroid-365cf.firebaseapp.com",
  projectId: "weweandroid-365cf",
  storageBucket: "weweandroid-365cf.appspot.com",
  messagingSenderId: "1088235860778",
  appId: "1:1088235860778:web:188370cd72085086fae453",
  measurementId: "G-7V7L2B41TP"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const checkRemotePermission = (permissionData)=>{
  if (permissionData.permission === 'default') {
     
    window.safari.pushNotification.requestPermission(
        'https://chat2.weltwelle.com/', 
        'web.chat.weltwelle.com',     
        {}, // Data that you choose to send to your server to help you identify the user.
        checkRemotePermission
    );
  }
  else if (permissionData.permission === 'denied') {
          console.war('User not allowed notifications');
           
  }
  else if (permissionData.permission === 'granted') {

      console.log(permissionData.deviceToken, 'YEAH!');
       
      // $rootScope.sendTokenToServer(permissionData.deviceToken, 'safari');
  }

}

export const fetchToken = () => {
  if(window.safari&& window.safari.pushNotification){
    var permissionData = window.safari.pushNotification.permission('web.chat.weltwelle.com');  
    checkRemotePermission(permissionData);

  }else{
  
    return getToken(messaging, {vapidKey: 'BDps-OXBPRZ1dmscpHC6b_9LDuuVoSfidg_d1u9-tuHMPPhz3RZduA7SjNMFuKdCTYAZPoIvloa_zrZkqrXz_88'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        // shows on the UI that permission is required 
      }
    
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

