import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import ChatPage from './pages/ChatPage/ChatPage';
import LoadingPage from './pages/LoadingPage/LoadingPage';
import LoginPage from './pages/Auth/LoginPage/LoginPage';
import RegistrationPage from './pages/Auth/RegistrationPage/RegistrationPage';
import RestorePage from './pages/Auth/RestorePage/RestorePage';
import HomePage from './pages/HomePage/HomePage';
import DirectoryPage from './pages/DirectoryPage/DirectoryPage';
import AdminUserPage from './pages/AdminUserPage/AdminUserPage';
import CreateChatPage from './pages/CreateChatPage/CreateChatPage';




const UseRoutes = (props) => {
    
    return(
        <Switch>
          {!props.auth.isLoggedIn && <Route path='/login' exact render={()=><LoginPage/>}/>}
          {!props.auth.isLoggedIn &&<Route path='/registration' exact render={()=><RegistrationPage/>}/>}
          {!props.auth.isLoggedIn &&<Route path='/restore' exact render={()=><RestorePage/>}/>}
          {!props.auth.isLoggedIn &&<Redirect  path='/' to='/login'/>}

          {props.auth.isLoggedIn && <Route path='/home' exact render={()=><HomePage/>}/>}
          {props.auth.isLoggedIn &&<Route path='/chat/:id' exact render={()=><ChatPage ws={props.ws} readyWs={props.readyWs} received={props.received}   />}/>}
          {props.auth.isLoggedIn &&<Route path='/createChat' exact render={()=><CreateChatPage ws={props.ws} readyWs={props.readyWs} received={props.received}   />}/>}
           
          
          {props.auth.isLoggedIn && props.currentUser && props.currentUser.roleId==='631AbA1f' && <Route path='/userlist' render={()=><AdminUserPage/>}/>}
          {props.auth.isLoggedIn && props.currentUser && props.currentUser.roleId==='631AbA1f' &&  <Route path='/dictionary' exact render={()=><DirectoryPage/>}/>}


          {<Route path='/loading' exact render={()=><LoadingPage/>}/>}

          {/* {props.auth.isLoggedIn && <Redirect path='/' to='/home'/>} */}
        
        </Switch>     
    
    )
}


export default UseRoutes;