import {
  CHATROOMLIST_SUCCESS,
  CHATROOMLIST_FAIL,
  MESSAGESADD_ANOTHERCHAT_SUCCESS,
  CHATROOMADD_SUCCESS,
  CHATROOMADD_FAIL,
  CHATROOMREADED_SUCCESS,
  CHATROOMDELETE_SUCCESS,
  CHATROOMDELETE_FAIL,
}from "../actions/types";

const initialState = [];

export default function chatroomReducer(chatroom = initialState, action) {
    const { type,list,item,status,id } = action;
      
    switch (type) {
          case CHATROOMLIST_FAIL:
            return [];
          case CHATROOMLIST_SUCCESS:
            return [...list];
          case CHATROOMDELETE_SUCCESS:
            chatroom.forEach((el,index)=>{
              if(el.id===item.result.deletedChatId){
                chatroom.splice(index,1)
              }
          })
            return  chatroom.slice(0);
          case CHATROOMADD_FAIL:
          return  chatroom;
          case CHATROOMADD_SUCCESS:
            chatroom.unshift(item);
            return  chatroom.slice(0);

          case CHATROOMREADED_SUCCESS:
            
            chatroom.forEach((el)=>{
              if(el.id===id.result.chatId){
                 
                  if(status==='increase'){
                    ++el.totalUnreadMessages;
                  }else if(status==='decrease' &&el.totalUnreadMessages>0){
                    --el.totalUnreadMessages;
                  }
                }
              })           
            return chatroom.slice(0);;
          case MESSAGESADD_ANOTHERCHAT_SUCCESS:
            let a = false;
            chatroom.forEach((el,index)=>{
              if(el.id===item.chatId){
                  el.lastMessage = item;
                  a  = true
                  if(index>0){
                    chatroom.splice(index,1)
                    chatroom.unshift(el)
                  }
                   
                  if(status==='increase' && !item.isMine){
                    ++el.totalUnreadMessages;
                  }else if(status==='decrease' &&el.totalUnreadMessages>0){
                    --el.totalUnreadMessages;
                  }
                }
              })
            
            if(!a){
              chatroom.unshift(item.chat);
              chatroom[0].lastMessage=item;
            }
             
            return chatroom.slice(0);;
        default:
          return chatroom;
    }
}