import {
    GETCURRENTUSER
} from "../actions/types";
  
const initialState = [];

export default function usersReducer(currentUser = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GETCURRENTUSER:
          return payload;
        default:
          return currentUser;
      }

}

