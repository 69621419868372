import React from "react";
import { useEffect,useRef } from "react";


const Timer =(props)=>{

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
  useInterval(() => {
    props.setTimer(props.timer - 1);
  }, 1000);
  useEffect(()=>{
    if(props.timer==0&& props.ws.current.readyState===1){
        let data ={
                    "action":"ping",
                    "data":{}
                }
                props.ws.current.send(JSON.stringify(data))
        props.setTimer(55)
    }
    },[props.timer])
  

    return(
        <>
        </>
    )
}

export default Timer;